import React from "react";
import { CustomDialog } from "../../UI/CustomDialog";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { t } from "i18next";
import { useForm, Controller } from "react-hook-form";
import { HelperIcon } from "../atoms/HelperIcon";
import { VisiblityHelperText } from "../atoms/VisiblityHelperText";
import { useStyles } from "../../utils/Theme";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector } from "react-redux";
import {
  autoCompleteDropdownValidation,
  formatFreeSoloChipInput,
  getLabelAndValue,
} from "../../utils/functions";
import {
  educationAreaList,
  educationLevelList,
  educationQualificationList,
  visibilityTypes,
} from "../../utils/ListItems";
import { useEditLgAboutMutation } from "../../redux/slice/lgabout";

export const LgEducation = ({
  open,
  mode,
  close = () => {},
  edu_lvl = null,
  edu_area = null,
  edu_details = null,
  edu_visib = "dist",
  schools = null,
  colleges = null,
  edu_qual = null,
  lg_id = "",
  lg_url = "",
  onNext = () => {},
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const defaultValues = {
    edu_lvl: edu_lvl ? getLabelAndValue(edu_lvl, educationLevelList) : edu_lvl,
    edu_area: edu_area !== null ? edu_area.split("|") : [],
    edu_details: edu_details,
    edu_visib: edu_visib === null ? "dist" : edu_visib,
    schools: schools !== null ? schools.split("|") : [],
    colleges: colleges !== null ? colleges.split("|") : [],
    edu_qual: edu_qual !== null ? edu_qual.split("|") : [],
    lg_id: lg_id,
    url: "lgEdu",
    lg_url: lg_url,
  };
  const { device } = useSelector((state) => state.config);
  const mobile = device === "xs" || device === "sm" || device === "md";
  const { handleSubmit, control, formState } = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const [editLg, { isLoading }] = useEditLgAboutMutation();
  const classes = useStyles();

  //-----------------------Declare Functions Here-----------------------------//

  const formatData = (data) => {
    data.edu_lvl = data.edu_lvl?.value ? data.edu_lvl?.value : data.edu_lvl;
    data.edu_area = data.edu_area.length > 0 ? data.edu_area.join("|") : null;
    data.schools = data.schools.length > 0 ? data.schools.join("|") : null;
    data.colleges = data.colleges.length > 0 ? data.colleges.join("|") : null;
    data.edu_qual = data.edu_qual.length > 0 ? data.edu_qual.join("|") : null;
    editLg(data)
      .then(() => {
        if (mode === "self" || mode === "new") {
          onNext();
        } else {
          close();
        }
      })
      .catch(() => close());
  };
  let dummy = {
    isValid: formState.isValid,
    isDirty: formState.isDirty,
  };
  console.log(dummy);
  return (
    <>
      <CustomDialog
        open={open}
        close={close}
        title={
          <Typography
            variant="h6"
            sx={{ fontSize: "22px", fontWeight: 500, color: "#333333" }}
            align="center"
          >
            {t("text.editModeLgEducationTitle")}
          </Typography>
        }
        action={
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <LoadingButton
              variant="contained"
              loading={isLoading}
              disabled={!(formState.isDirty && formState.isValid)}
              onClick={handleSubmit((data) => formatData(data))}
            >
              {t("button.save")}
            </LoadingButton>
          </Box>
        }
      >
        <Card sx={{ width: "100%", p: 1 }}>
          <Stack spacing={2}>
            <Controller
              name={"edu_lvl"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  {...field}
                  value={field.value}
                  options={educationLevelList}
                  freeSolo={false}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <Box>
                      <TextField
                        {...params}
                        label={t("inputField.educationLevel")}
                        error={fieldState.error ? true : false}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    </Box>
                  )}
                  onChange={(_, data) => field.onChange(data)}
                  onInputChange={(_, data) => field.onChange(data)}
                />
              )}
              rules={{
                validate: (value) =>
                  autoCompleteDropdownValidation(value, educationLevelList) ||
                  t("error.autoselectDropdownError"),
              }}
            />

            <Controller
              name={"edu_qual"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  clearOnBlur
                  {...field}
                  multiple
                  options={educationQualificationList}
                  freeSolo={true}
                  onChange={(_, data) =>
                    field.onChange(formatFreeSoloChipInput(data))
                  }
                  onInputChange={(_, newInputValue) => {
                    const options = newInputValue.split(",");
                    if (options.length > 1) {
                      field.onChange(
                        field.value
                          .concat(options)
                          .map((x) => x.trim())
                          .filter((x) => x)
                      );
                    }
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      let getLabel = getLabelAndValue(option, []);
                      return (
                        <Chip
                          variant="outlined"
                          label={getLabel ? getLabel.label : option}
                          {...getTagProps({ index })}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("inputField.educationQualification")}
                      error={fieldState.error ? true : false}
                      helperText={
                        fieldState.error
                          ? fieldState.error.message
                          : mobile
                          ? t("helperText.chipMobile")
                          : t("helperText.chipDesktop")
                      }
                      // placeholder="Favorites"
                    />
                  )}
                />
              )}
              rules={{
                validate: {
                  lengthOfEachChip: (value) => {
                    return (
                      value.every((item) => item.length <= 50) ||
                      `${t("error.noMoreChar")
                        .split(" ")
                        .slice(0, 6)
                        .join(" ")} 50 ${t("error.noMoreChar").split(" ")[6]}`
                    );
                  },
                  chipArrayCount: (value) =>
                    value.length <= 15 ||
                    `${t("error.maxChipEntryError").split(" ")[0]} 15 ${t(
                      "error.maxChipEntryError"
                    ).slice(5)}`,
                  checkforspecialCharacters: (value) =>
                    value.every((item) => item.includes("|") === false) ||
                    t("error.pipeOperatorError"),
                },
              }}
            />

            <Controller
              name={"edu_area"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  clearOnBlur
                  {...field}
                  multiple
                  options={educationAreaList}
                  freeSolo={false}
                  onChange={(_, data) =>
                    field.onChange(formatFreeSoloChipInput(data))
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      let getLabel = getLabelAndValue(
                        option,
                        educationAreaList
                      );
                      return (
                        <Chip
                          variant="outlined"
                          label={getLabel ? getLabel.label : option}
                          {...getTagProps({ index })}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("inputField.educationArea")}
                      error={fieldState.error ? true : false}
                      helperText={
                        fieldState.error
                          ? fieldState.error.message
                          : mobile
                          ? t("helperText.chipMobile")
                          : t("helperText.chipDesktop")
                      }
                      // placeholder="Favorites"
                    />
                  )}
                />
              )}
              rules={{
                validate: {
                  lengthOfEachChip: (value) => {
                    return (
                      value.every((item) => item.length <= 50) ||
                      `${t("error.noMoreChar")
                        .split(" ")
                        .slice(0, 6)
                        .join(" ")} 50 ${t("error.noMoreChar").split(" ")[6]}`
                    );
                  },
                  chipArrayCount: (value) =>
                    value.length <= 15 ||
                    `${t("error.maxChipEntryError").split(" ")[0]} 15 ${t(
                      "error.maxChipEntryError"
                    ).slice(5)}`,
                  checkforspecialCharacters: (value) =>
                    value.every((item) => item.includes("|") === false) ||
                    t("error.pipeOperatorError"),
                },
              }}
            />

            <Controller
              name={"schools"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  clearOnBlur
                  {...field}
                  multiple
                  options={[]}
                  freeSolo={true}
                  onChange={(_, data) =>
                    field.onChange(formatFreeSoloChipInput(data))
                  }
                  onInputChange={(_, newInputValue) => {
                    const options = newInputValue.split(",");
                    if (options.length > 1) {
                      field.onChange(
                        field.value
                          .concat(options)
                          .map((x) => x.trim())
                          .filter((x) => x)
                      );
                    }
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      let getLabel = getLabelAndValue(option, []);
                      return (
                        <Chip
                          variant="outlined"
                          label={getLabel ? getLabel.label : option}
                          {...getTagProps({ index })}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("inputField.schoolsAttended")}
                      error={fieldState.error ? true : false}
                      helperText={
                        fieldState.error
                          ? fieldState.error.message
                          : mobile
                          ? t("helperText.chipMobile")
                          : t("helperText.chipDesktop")
                      }
                      // placeholder="Favorites"
                    />
                  )}
                />
              )}
              rules={{
                validate: {
                  lengthOfEachChip: (value) => {
                    return (
                      value.every((item) => item.length <= 75) ||
                      `${t("error.noMoreChar")
                        .split(" ")
                        .slice(0, 6)
                        .join(" ")} 75 ${t("error.noMoreChar").split(" ")[6]}`
                    );
                  },
                  chipArrayCount: (value) =>
                    value.length <= 12 ||
                    `${t("error.maxChipEntryError").split(" ")[0]} 12 ${t(
                      "error.maxChipEntryError"
                    ).slice(5)}`,
                  checkforspecialCharacters: (value) =>
                    value.every((item) => item.includes("|") === false) ||
                    t("error.pipeOperatorError"),
                },
              }}
            />

            <Controller
              name={"colleges"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  clearOnBlur
                  {...field}
                  multiple
                  options={[]}
                  freeSolo={true}
                  onChange={(_, data) =>
                    field.onChange(formatFreeSoloChipInput(data))
                  }
                  onInputChange={(_, newInputValue) => {
                    const options = newInputValue.split(",");
                    if (options.length > 1) {
                      field.onChange(
                        field.value
                          .concat(options)
                          .map((x) => x.trim())
                          .filter((x) => x)
                      );
                    }
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      let getLabel = getLabelAndValue(option, []);
                      return (
                        <Chip
                          variant="outlined"
                          label={getLabel ? getLabel.label : option}
                          {...getTagProps({ index })}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("inputField.collegesAttendes")}
                      error={fieldState.error ? true : false}
                      helperText={
                        fieldState.error
                          ? fieldState.error.message
                          : mobile
                          ? t("helperText.chipMobile")
                          : t("helperText.chipDesktop")
                      }
                      // placeholder="Favorites"
                    />
                  )}
                />
              )}
              rules={{
                validate: {
                  lengthOfEachChip: (value) => {
                    return (
                      value.every((item) => item.length <= 75) ||
                      `${t("error.noMoreChar")
                        .split(" ")
                        .slice(0, 6)
                        .join(" ")} 75 ${t("error.noMoreChar").split(" ")[6]}`
                    );
                  },
                  chipArrayCount: (value) =>
                    value.length <= 15 ||
                    `${t("error.maxChipEntryError").split(" ")[0]} 15 ${t(
                      "error.maxChipEntryError"
                    ).slice(5)}`,
                  checkforspecialCharacters: (value) =>
                    value.every((item) => item.includes("|") === false) ||
                    t("error.pipeOperatorError"),
                },
              }}
            />

            <Controller
              name={"edu_details"}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("inputField.AdditionalDetails")}
                  type={"text"}
                  inputProps={{
                    maxLength: 1001,
                  }}
                  error={fieldState.error ? true : false}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                  InputProps={{
                    inputComponent: TextareaAutosize,
                    endAdornment: (
                      <HelperIcon
                        helperText={
                          <Typography variant="body1">
                            {t("helperText.additionalDetails")}
                          </Typography>
                        }
                      />
                    ),
                  }}
                  sx={{ width: "100%" }}
                />
              )}
              rules={{
                maxLength: {
                  value: 1000,
                  message: t("error.maxCharacters", {
                    param1: 1000,
                  }),
                },
              }}
            />

            <Controller
              name={"edu_visib"}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  classes={{
                    root: classes.textFieldRoot, // apply class here
                  }}
                  select={true}
                  label={t("inputField.Visiblity")}
                  value={field.value}
                  required={true}
                  InputProps={{
                    endAdornment: (
                      <HelperIcon helperText={<VisiblityHelperText />} />
                    ),
                  }}
                  sx={{ width: "100%" }}
                  error={fieldState.error ? true : false}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                >
                  {visibilityTypes.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: {
                  value: true,
                  message: t("error.fieldIsRequired"),
                },
              }}
            />
          </Stack>
        </Card>
      </CustomDialog>
    </>
  );
};
