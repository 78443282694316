import React from "react";
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/system/Box";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { t } from "i18next";
import { resetPasswordApi } from "../../api/resetPasswordApi";
import { regularExpressions } from "../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";

const ResetPassword = () => {
  //-----------------------Declare Hooks Here-----------------------------//
  const [loading, setLoading] = useState(false);
  const { urlParams } = useParams();
  const navigate = useNavigate();
  const defaultValues = {
    emailId: urlParams.split("|")[0],
    resetToken: urlParams.split("|")[1],
    password: "",
    cpassword: "",
  };
  const { handleSubmit, control, formState, getValues } = useForm({
    mode: "onChange",
    defaultValues,
  });

  //-----------------------Declare Functions Here-----------------------------//
  const formSubmit = async (data) => {
    console.log(data);
    if (formState.errors) {
      setLoading(true);
      resetPasswordApi(data)
        .then((response) => {
          if (!response.error) {
            navigate("/");
            setLoading(false);
          } else {
            setLoading(false);
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    }
  };
  const handelKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSubmit((data) => formSubmit(data))();
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        px: 1,
      }}
    >
      <Box sx={{ width: "100%", maxWidth: "480px" }}>
        <Stack spacing={5}>
          <Typography variant="h5" align="center">
            {t("text.resetPassword")}
          </Typography>
          <Stack spacing={2}>
            <Controller
              name={"password"}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("inputField.password")}
                  required={true}
                  type={"password"}
                  variant={"outlined"}
                  error={fieldState.error ? true : false}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                  sx={{ width: "100%" }}
                  onKeyDown={handelKeyDown}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: t("error.fieldIsRequired", {
                    param1: t("inputField.password"),
                  }),
                },
                pattern: {
                  value: regularExpressions.password,
                  message: t("error.passwordValidationErr"),
                },
              }}
            />
            <Controller
              name={"cpassword"}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("inputField.confirmPassword")}
                  type={"password"}
                  required={true}
                  variant={"outlined"}
                  error={fieldState.error ? true : false}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                  sx={{ width: "100%" }}
                  onKeyDown={handelKeyDown}
                />
              )}
              rules={{
                required: {
                  value: true,
                  message: t("error.fieldIsRequired", {
                    param1: t("inputField.confirmPassword"),
                  }),
                },
                validate: {
                  positive: (value) =>
                    getValues("password") === value ||
                    t("error.passwordMissMatchErr"),
                },
              }}
            />
          </Stack>
          <LoadingButton
            variant="contained"
            type={"submit"}
            sx={{
              width: "100%",
              my: 2,
              fontFamily: "Lato",
              fontSize: "16px",
            }}
            loading={loading}
            disabled={!formState.isValid}
            onClick={handleSubmit((data) => formSubmit(data))}
          >
            {t("button.reset")}
          </LoadingButton>
        </Stack>
      </Box>
    </Box>
  );
};
export default ResetPassword;
