import React from "react";
import { useSelector } from "react-redux";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import { t } from "i18next";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="caption" component="div" color="text.secondary">
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export const FileUploadBuzzyPointer = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  const uploadProgress = useSelector((state) => state.uploadProgress);
  return (
    <Dialog open={Boolean(uploadProgress)}>
      <Card sx={{ p: 1 }}>
        <Stack spacing={1}>
          <Typography>{t("text.uploadingImage")}</Typography>
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CircularProgressWithLabel value={uploadProgress} />
          </Box>
        </Stack>
      </Card>
    </Dialog>
  );
};
