import React, { useRef, useState } from "react";
import { useLazyGetSearchQuery } from "../../redux/slice/search";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import { searchtabs } from "../../utils/ListItems";
import { t } from "i18next";
import { LifeEventContainer } from "../organism/LifeEventContainer";
import { MomentsContainer } from "../organism/MomentsContainer";
import { AchievementContainer } from "../organism/AchievementContainer";
import { AnecdotesContainer } from "../organism/AnecdotesContainer";
import { WisdomContainer } from "../organism/WisdomContainer";
import { AnnouncementContainer } from "../organism/AnnouncementContainer";
import { LifeEventSkleton } from "../Skleton/LifeEventSkleton";
import { MomentsSkleton } from "../Skleton/MomentsSkleton";
import { AnecdotesSkleton } from "../Skleton/AnecdotesSkleton";
import { WisdomSkleton } from "../Skleton/WisdomSkleton";
import Typography from "@mui/material/Typography";
import { UserNameCard } from "../atoms/UserNameCard";
import { findOwner, formatRelationship } from "../../utils/functions";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { ReactionCountSkleton } from "../Skleton/ReactionCount";

export const Seacrch = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  let timer = useRef();
  const [getSearch, { data, isLoading, isFetching }] = useLazyGetSearchQuery();
  const navigate = useNavigate();
  const [type, setType] = useState("nameurl");
  const [value, setValue] = useState("");
  //-----------------------Declare Functions Here-----------------------------//

  const apiFunction = (value, type) => {
    setValue(value);
    if (value.length > 3) {
      return new Promise(() => {
        clearTimeout(timer.current);
        timer.current = setTimeout(async () => {
          getSearch({ type: type, string: value }, true);
        }, 1000);
      });
    }
  };

  const handleCategoryChange = (e) => {
    setType(e.target.value);
    apiFunction(value, e.target.value);
  };

  return (
    <Box sx={{ minHeight: "86vh" }}>
      <Stack spacing={1}>
        <Card sx={{ p: 0.5 }}>
          <Stack
            direction={"row"}
            sx={{
              display: "flex",
              p: "1px",
              backgroundColor: "transparent",
              borderRadius: 1,
            }}
            spacing={1}
            id="search-category-select"
          >
            <Select
              onChange={handleCategoryChange}
              value={type}
              sx={{
                backgroundColor: "#fff",
                minWidth: "105px",
                maxWidth: "105px",
                width: "105px",
                ".MuiSelect-select": { p: 0, pl: 1 },
                borderTopRightRadius: 0,
                borderBottomRightRadius: 0,
              }}
            >
              {searchtabs.map((item, idx) => (
                <MenuItem key={idx} value={item.value}>
                  {t(`button.${item.label}`)}
                </MenuItem>
              ))}
            </Select>
            <TextField
              placeholder="Search : family member, life event, cher...."
              sx={{
                width: "100%",
                "& ::before": { display: "none" },
                "& ::after": { display: "none" },
                "& div": {
                  borderRadius: 1,
                  backgroundColor: "#fff",
                },
                "& div > input": { px: 1, py: 1.5 },
              }}
              variant="filled"
              InputProps={{
                endAdornment: (
                  <InputAdornment>
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => apiFunction(e.target.value, type)}
            />
          </Stack>
        </Card>
        <>
          <>
            {(data?.searchList.length === 0 || value.length <= 3) &&
              !isFetching && (
                <Card
                  sx={{
                    width: "100px",
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    alignItems: "center",
                    minHeight: "57.2vh",
                    backgroundColor: "transparent",
                  }}
                >
                  <Typography sx={{ color: "grey.text" }}>
                    {data && data?.searchList.length === 0 && value.length > 3
                      ? t("text.nothingFound")
                      : t("text.somethingToSearch")}
                  </Typography>
                </Card>
              )}
            {(isLoading || isFetching) && (
              <>
                {" "}
                {type === "nameurl" && (
                  <Stack spacing={1}>
                    {Array(4)
                      .fill()
                      .map((_, idx) => (
                        <Card key={idx} sx={{ p: 1 }}>
                          <ReactionCountSkleton />
                        </Card>
                      ))}
                  </Stack>
                )}
                {type === "le" && <LifeEventSkleton />}
                {type === "mem" && <MomentsSkleton />}
                {type === "ach" && <MomentsSkleton />}
                {type === "impress" && <AnecdotesSkleton />}
                {type === "wisdom" && <WisdomSkleton />}
                {type === "anc" && <MomentsSkleton />}
              </>
            )}

            {!isFetching &&
              data &&
              data?.searchList.length > 0 &&
              value.length > 3 && (
                <Stack spacing={1}>
                  {data?.searchList.map((item, idx) => (
                    <div key={idx}>
                      {item?.catg === "nameurl" && (
                        <Card sx={{ p: 1 }}>
                          <Button
                            sx={{ width: "100%" }}
                            onClick={() =>
                              navigate(
                                `/profile/${item?.link.split("/")[4]}/about`
                              )
                            }
                          >
                            <UserNameCard
                              src={item.readURL}
                              name={item.name}
                              relation={formatRelationship(item.userId)}
                              ownerType={findOwner(item.userId)?.owner_type}
                            />
                          </Button>
                        </Card>
                      )}
                      {item?.catg === "le" && (
                        <LifeEventContainer searchValue={value} {...item} />
                      )}
                      {item?.catg === "mem" && (
                        <MomentsContainer searchValue={value} {...item} />
                      )}
                      {item?.catg === "ach" && (
                        <AchievementContainer searchValue={value} {...item} />
                      )}
                      {item?.catg === "impress" && (
                        <AnecdotesContainer searchValue={value} {...item} />
                      )}
                      {item?.catg === "wisdom" && (
                        <WisdomContainer searchValue={value} {...item} />
                      )}
                      {item?.catg === "anc" && (
                        <AnnouncementContainer searchValue={value} {...item} />
                      )}
                    </div>
                  ))}
                </Stack>
              )}
          </>
        </>
      </Stack>
    </Box>
  );
};
