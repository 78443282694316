import React, { useEffect, useState } from "react";
import { CustomDialog } from "../../UI/CustomDialog";
import Card from "@mui/material/Card";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import MenuItem from "@mui/material/MenuItem";
import { t } from "i18next";
import { visibilityTypes } from "../../utils/ListItems";
import { CustomTextArea } from "../../UI/CustomTextArea";
import {
  findAllowedFileType,
  findEventType,
  findFileCode,
  findWho,
  generateFileType,
  validatePlan,
  validateRichText,
} from "../../utils/functions";
import { FileUploadButton } from "../../UI/FileUploadButton";
import { useStyles } from "../../utils/Theme";
import { HelperIcon } from "../atoms/HelperIcon";
import { VisiblityHelperText } from "../atoms/VisiblityHelperText";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import { useDispatch } from "react-redux";
import { setAlert } from "../../redux/slice/alert";
import { Img } from "../../UI/Img";
import {
  useEditAnnouncementMutation,
  usePostAnnouncementMutation,
} from "../../redux/slice/announcement";
import { UpgradePromt } from "../../UI/UpgradePromt";
import CustomAvatar from "../../UI/CustomAvathar";

export const NewRandomThoughts = (props) => {
  //-----------------------Declare Hooks Here-----------------------------//
  const {
    open,
    close,
    mode = "new",
    comment_cnt,
    created_lg,
    created_lg_url,
    desc,
    link,
    dt,
    entry_created_by,
    file_type,
    id,
    my_reaction,
    reaction_cnt,
    readURL,
    title,
    uniq_reaction_list,
    updated_date,
    visibility,
    searchValue = "",
    user_connect_to_lg,
    lg_id,
    fstr,
    typ = "anc",
  } = props;
  const [selectedFile, setSelectedFile] = useState("NOFILE");
  const [linkType, setLinkType] = useState("l");
  const [size, setSize] = useState(0);
  const [type, setType] = useState(
    mode === "edit" ? file_type.split("/")[0] : null
  );
  const [plan, setPlan] = useState(mode === "new" ? validatePlan() : "active");
  const classes = useStyles();
  const who = findWho(lg_id);
  const dispatch = useDispatch();
  const [postAnnouncement, { status: postStatus }] =
    usePostAnnouncementMutation();
  const [editAnnouncement, { status: editStatus }] =
    useEditAnnouncementMutation();
  const defaultValues = {
    type: { value: "random" },
    title: mode === "edit" ? title : "",
    dt: mode === "edit" ? dt : "",
    desc: mode === "edit" ? desc : "",
    visibility: mode === "edit" ? visibility : "dist",
    file: mode === "edit" ? readURL : "",
    selectedFileType:
      mode === "edit"
        ? file_type === "NOFILE"
          ? "none"
          : file_type.split("/")[0]
        : "none",
    file_type: mode === "edit" ? file_type : "NOFILE",
    file_code: "",
  };
  const { handleSubmit, control, formState, watch, getValues, setValue } =
    useForm({
      mode: "onChange",
      defaultValues,
    });
  useEffect(() => {
    let value = getValues("selectedFileType");
    setValue(
      "file_code",
      findFileCode(lg_id, getValues("selectedFileType"), typ)
    );
    let nofile = value === "link" || value === "NOFILE" || value === "none";
    if (value) {
      if (value !== "link") {
        setLinkType("");
      }
      if (nofile) {
        setSelectedFile("NOFILE");
      } else if (
        (mode === "edit" && type !== file_type.split("/")[0]) ||
        selectedFile !== "NOFILE"
      ) {
        setSelectedFile("NOFILE");
      }
      setType(
        nofile ? (value === "none" ? "" : "NOFILE") : value?.split(" ")[0]
      );
    }
  }, [watch("selectedFileType")]);

  //-----------------------Declare Functions Here-----------------------------//

  const handleFileSelection = (value, updateFormData) => {
    let tempFile = value.target.files[0];
    let validateFile = findAllowedFileType(
      lg_id,
      typ,
      tempFile.type,
      getValues("selectedFileType"),
      type
    );
    if (validateFile.isValidMimeType) {
      if (tempFile.type.split("/")[0] === "image") {
        setSelectedFile(tempFile);
        setValue("file_type", tempFile.type);
        updateFormData(tempFile);
        setSize(validateFile.maxSize);
      } else {
        if (
          Number(tempFile.size / 1024 / 1024) < Number(validateFile.maxSize)
        ) {
          setSelectedFile(tempFile);
          setValue("file_type", tempFile.type);
          updateFormData(tempFile);
          setSize(validateFile.maxSize);
        } else {
          dispatch(
            setAlert({
              error: true,
              message: "fileTooBig",
              severity: "error",
              param: { param1: `${validateFile.maxSize} MB` },
            })
          );
        }
      }
    } else {
      dispatch(
        setAlert({ error: true, message: "incorrectFormat", severity: "error" })
      );
    }
  };
  const formatData = (data) => {
    let plan = validatePlan(typ, data.file_code);
    if (plan === "upgrade") {
      setPlan(plan);
    } else {
      if (mode === "new") {
        postAnnouncement({
          ...data,
          lg_id: lg_id,
          typ: typ,
          file: selectedFile,
          size: size,
        }).then(() => close(false));
      } else if (mode === "edit") {
        editAnnouncement({
          ...data,
          lg_id: lg_id,
          typ: typ,
          size: size,
          fstr: fstr,
          id: id,
          file_type:
            readURL === "NOFILE" && selectedFile !== "NOFILE"
              ? selectedFile.type
              : readURL !== "NOFILE" && selectedFile !== "NOFILE"
              ? selectedFile.type
              : readURL !== "NOFILE" &&
                selectedFile === "NOFILE" &&
                (data.selectedFileType === "none" ||
                  data.selectedFileType === "link")
              ? "NOFILE"
              : file_type,
          file_upd_flag:
            readURL === "NOFILE" && selectedFile !== "NOFILE"
              ? "UPDATE"
              : readURL !== "NOFILE" && selectedFile !== "NOFILE"
              ? "UPDATE"
              : readURL !== "NOFILE" &&
                selectedFile === "NOFILE" &&
                (data.selectedFileType === "none" ||
                  data.selectedFileType === "link")
              ? "DELETE"
              : "",
        }).then(() => close(false));
      }
    }
  };
  let enablebutton =
    mode === "new"
      ? !formState.isValid
      : type === ""
      ? !(formState.isDirty && formState.isValid)
      : type !== "NOFILE"
      ? !(formState.isDirty && formState.isValid) ||
        (type !== file_type.split("/")[0] && selectedFile === "NOFILE")
      : !(formState.isDirty && formState.isValid);
  // do not delete the below variable
  let dummy = {
    disable: enablebutton,
    isValid: formState.isValid,
    isDirty: formState.isDirty,
  };

  return (
    <>
      {plan === "active" && (
        <CustomDialog
          open={open}
          close={close}
          title={
            <Stack
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
              spacing={1}
              direction={"row"}
            >
              <CustomAvatar src={who?.readURL} />
              <Box>
                <Typography
                  variant="h6"
                  component={"div"}
                  align="center"
                  sx={{ fontSize: "22px", fontWeight: 500, color: "#333333" }}
                >
                  {who?.name}
                </Typography>
                <Typography
                  variant="caption"
                  component={"div"}
                  sx={{ fontSize: "14px" }}
                >
                  {t(`button.${findEventType(typ).title}`, {
                    count: 1,
                  })}
                </Typography>
              </Box>
            </Stack>
          }
          action={
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <LoadingButton
                variant="contained"
                disabled={enablebutton}
                onClick={handleSubmit((data) => formatData(data))}
                loading={postStatus === "pending" || editStatus === "pending"}
              >
                <Typography>
                  {t(`button.${mode === "new" ? "create" : "edit"}`)}
                </Typography>
              </LoadingButton>
            </Box>
          }
        >
          <Card sx={{ width: "100%", p: 1 }}>
            <Stack spacing={2}>
              {/* <Controller
                name={"type"}
                control={control}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    clearOnBlur
                    {...field}
                    value={field.value}
                    options={announcementTypes}
                    autoHighlight={true}
                    freeSolo={false}
                    isOptionEqualToValue={(option, value) => option === value}
                    renderOption={(props, option) => (
                      <Box component="li" {...props}>
                        {option.label}
                      </Box>
                    )}
                    renderInput={(params) => (
                      <Box>
                        <TextField
                          {...params}
                          label={t("inputField.announcementType")}
                          required={true}
                          error={fieldState.error ? true : false}
                          helperText={
                            fieldState.error ? fieldState.error.message : null
                          }
                        />
                      </Box>
                    )}
                    onChange={(_, data) => field.onChange(data)}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: t("error.fieldIsRequired", {
                      param1: t("inputField.eventType"),
                    }),
                  },
                }}
              /> */}
              <Controller
                name={"title"}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label={t("inputField.title")}
                    type={"text"}
                    inputProps={{
                      maxLength: 46,
                    }}
                    required={true}
                    error={fieldState.error ? true : false}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    sx={{ width: "100%" }}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: t("error.fieldIsRequired", {
                      param1: t("inputField.title"),
                    }),
                  },
                  maxLength: {
                    value: 45,
                    message: t("error.maxCharacters", {
                      param1: 45,
                    }),
                  },
                }}
              />
              <Controller
                name={"desc"}
                control={control}
                render={({ field, fieldState }) => (
                  <CustomTextArea
                    onChange={field.onChange}
                    error={fieldState.error}
                    label={t("inputField.description")}
                    value={field.value}
                    required={true}
                    maxLength={251}
                    lg_id={lg_id}
                  />
                )}
                rules={{
                  validate: {
                    length: (value) =>
                      value.blocks[0].text.length <= 250 ||
                      t("error.maxCharacters", {
                        param1: 250,
                      }),
                    required: (value) =>
                      validateRichText(
                        value.blocks[0].text,
                        formState.isDirty
                      ) === true ||
                      t("error.fieldIsRequired", {
                        param1: t("inputField.description"),
                      }),
                  },
                }}
              />
              <Controller
                name={"selectedFileType"}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    classes={{
                      root: classes.textFieldRoot, // apply class here
                    }}
                    select={true}
                    label={t("inputField.attach")}
                    value={field.value}
                    required={true}
                    sx={{ width: "100%" }}
                    error={fieldState.error ? true : false}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  >
                    {generateFileType(lg_id, typ).map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                rules={{
                  required: {
                    value: true,
                    message: t("error.fieldIsRequired"),
                  },
                }}
              />
              {type && (
                <>
                  {type !== "NOFILE" ? (
                    <Controller
                      key={"file"}
                      name={"file"}
                      control={control}
                      render={({ field }) => (
                        <FileUploadButton
                          variant={"contained"}
                          accept={
                            findAllowedFileType(
                              lg_id,
                              typ,
                              `${type}/*.`,
                              getValues("selectedFileType")
                            ).allowedMimeTypes
                          }
                          onChange={(e) =>
                            handleFileSelection(e, field.onChange)
                          }
                          value={
                            selectedFile === "NOFILE" ? false : selectedFile
                          }
                          readURL={
                            (mode === "new" || mode === "edit") &&
                            selectedFile !== "NOFILE"
                              ? URL.createObjectURL(selectedFile)
                              : mode === "edit" && selectedFile === "NOFILE"
                              ? readURL
                              : "NOFILE"
                          }
                          file_type={
                            mode === "new" && selectedFile !== "NOFILE"
                              ? selectedFile.type
                              : mode === "edit" && selectedFile !== "NOFILE"
                              ? selectedFile.type
                              : mode === "edit" && selectedFile === "NOFILE"
                              ? file_type
                              : "NOFILE"
                          }
                          mode={mode}
                          inputType={type}
                        >
                          {mode === "edit"
                            ? t("inputField.change", {
                                param1: t(`inputField.${type}`),
                              })
                            : t("inputField.upload", {
                                param1: t(`inputField.${type}`),
                              })}
                        </FileUploadButton>
                      )}
                      rules={{
                        required: {
                          value: true,
                          message: t("error.fieldIsRequired", {
                            param1: t("inputField.title"),
                          }),
                        },
                      }}
                    />
                  ) : null}
                </>
              )}
              <Controller
                name={"visibility"}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    classes={{
                      root: classes.textFieldRoot, // apply class here
                    }}
                    select={true}
                    label={t("inputField.Visiblity")}
                    value={field.value}
                    required={true}
                    InputProps={{
                      endAdornment: (
                        <HelperIcon helperText={<VisiblityHelperText />} />
                      ),
                    }}
                    error={fieldState.error ? true : false}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  >
                    {visibilityTypes.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                rules={{
                  required: {
                    value: true,
                    message: t("error.fieldIsRequired", {
                      param1: t("inputField.Visiblity"),
                    }),
                  },
                }}
              />
            </Stack>
          </Card>
        </CustomDialog>
      )}
      {plan === "upgrade" && (
        <>
          <UpgradePromt
            open={plan === "upgrade"}
            close={() => {
              close(false);
            }}
          />
        </>
      )}
    </>
  );
};
