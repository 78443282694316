import React from "react";
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/system/Box";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { t } from "i18next";
import { forgetPasswordEmailApi } from "../../api/forgetPasswordEmailApi";

const defaultValues = {
  emailid: "",
};

const ForgetPasswordEmail = () => {
  //-----------------------Declare Hooks Here-----------------------------//
  const [loading, setLoading] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const { handleSubmit, control, formState } = useForm({
    mode: "onChange",
    defaultValues,
  });

  //-----------------------Declare Functions Here-----------------------------//
  const formSubmit = async (data) => {
    if (formState.errors) {
      setLoading(true);
      forgetPasswordEmailApi(data)
        .then((response) => {
          if (!response.error) {
            setShowMessage(true);
            setLoading(false);
          } else {
            setShowMessage(true);
            setLoading(false);
          }
        })
        .catch((e) => {
          setLoading(false);
          console.log(e);
        });
    }
  };
  const handelKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSubmit((data) => formSubmit(data))();
    }
  };

  return (
    <Box
      sx={{
        height: "100vh",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        px: 1,
      }}
    >
      {!showMessage ? (
        <Box sx={{ width: "100%", maxWidth: "480px" }}>
          <Stack spacing={5}>
            <Typography variant="h5" align="center">
              {t("text.forgetPassword")}
            </Typography>
            <Typography variant="body1" align="center">
              {t("text.forgetPasswordDescription")}
            </Typography>
            <Stack spacing={2}>
              <Controller
                name={"emailid"}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    required={true}
                    label={t("inputField.email")}
                    type={"email"}
                    variant={"outlined"}
                    error={fieldState.error ? true : false}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    sx={{ width: "100%" }}
                    onKeyDown={handelKeyDown}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: t("error.fieldIsRequired", {
                      param1: t("inputField.email"),
                    }),
                  },
                }}
              />
            </Stack>
            <LoadingButton
              variant="contained"
              type={"submit"}
              sx={{
                width: "100%",
                my: 2,
                fontFamily: "Lato",
                fontSize: "16px",
              }}
              loading={loading}
              disabled={!formState.isValid}
              onClick={handleSubmit((data) => formSubmit(data))}
            >
              {t("button.reset")}
            </LoadingButton>
          </Stack>
        </Box>
      ) : (
        <Box sx={{ width: "100%", maxWidth: "480px" }}>
          <Stack spacing={5}>
            <Typography variant="h5" align="center">
              {t("text.forgetPassword")}
            </Typography>
            <Typography variant="body1" align="center">
              {t("text.forgetPasswordDescription")}
            </Typography>
            <LoadingButton
              variant="contained"
              type={"submit"}
              sx={{
                width: "100%",
                my: 2,
                fontFamily: "Lato",
                fontSize: "16px",
              }}
              loading={loading}
              disabled={!formState.isValid}
              onClick={handleSubmit((data) => formSubmit(data))}
            >
              {t("button.sendLinkAgain")}
            </LoadingButton>
          </Stack>
        </Box>
      )}
    </Box>
  );
};
export default ForgetPasswordEmail;
