import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { assetSource, badgeSrc } from "../../utils/ListItems";
import { capitalizeFirstLetter } from "../../utils/functions";
import { t } from "i18next";
import Odometer from "react-odometerjs";
import "odometer/themes/odometer-theme-default.css";

export const ProfileBadgeCard = ({
  lgScore = "0",
  engScore = "0",
  badge = "beginer",
}) => {
  return (
    <>
      <Card
        sx={{
          background: `url(${assetSource}/img_profileBadgeCardBG), linear-gradient(93.6deg, #AB82C3 -4.57%, #8C679E 28.57%, #8C679E 77.11%, #AB82C3 107.01%)`,
          height: "75px",
          maxHeight: "75px",
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            maxWidth: "75px",
            backgroundColor: "transparent",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "75px",
          }}
        >
          <Typography
            sx={{ fontSize: "14px", fontWeight: 600, color: "#ffffff" }}
            align="center"
          >
            {t("text.lgScore2")}
          </Typography>
          <Typography
            sx={{ fontSize: "20px", fontWeight: 600, color: "#ffffff" }}
            align="center"
          >
            <Odometer value={lgScore} theme="default" duration={1000} />
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: "75px",
            backgroundColor: "transparent",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "75px",
          }}
        >
          <Typography
            sx={{ fontSize: "14px", fontWeight: 600, color: "#ffffff" }}
            align="center"
          >
            {t("text.engScore")}
          </Typography>
          <Typography
            sx={{ fontSize: "20px", fontWeight: 600, color: "#ffffff" }}
            align="center"
          >
            <Odometer value={engScore} theme="default" duration={1000} />
          </Typography>
        </Box>
        <Box
          sx={{
            maxWidth: "75px",
            backgroundColor: "transparent",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: "75px",
          }}
        >
          <Typography
            sx={{ fontSize: "14px", fontWeight: 600, color: "#ffffff" }}
            align="center"
          >
            {t("text.bonding")}
          </Typography>
          <img style={{ height: "34px" }} src={badgeSrc[badge]} />
          <Typography
            sx={{ fontSize: "14px", fontWeight: 600, color: "#ffffff" }}
            align="center"
          >
            {capitalizeFirstLetter(badge)}
          </Typography>
        </Box>
      </Card>
    </>
  );
};
