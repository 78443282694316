import React, { useState } from "react";
import { CustomDialog } from "../../UI/CustomDialog";
import Card from "@mui/material/Card";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { t } from "i18next";
import { CustomTextArea } from "../../UI/CustomTextArea";
import {
  findEventType,
  findWho,
  validatePlan,
  validateRichText,
} from "../../utils/functions";
import Typography from "@mui/material/Typography";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  useEditAnecdotesMutation,
  usePostAnecdotesMutation,
} from "../../redux/slice/anecdotes";
import { UpgradePromt } from "../../UI/UpgradePromt";
import CustomAvatar from "../../UI/CustomAvathar";

export const NewAnecdotes = ({
  open,
  close,
  mode = "new",
  comment_cnt,
  created_lg,
  created_lg_url,
  desc,
  link,
  dt,
  entry_created_by,
  file_type,
  id,
  type,
  my_reaction,
  reaction_cnt,
  readURL,
  title,
  uniq_reaction_list,
  updated_date,
  visibility,
  searchValue = "",
  user_connect_to_lg,
  lg_id,
  fstr,
  typ = "impress",
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const [postAnecdotes, { status: postStatus }] = usePostAnecdotesMutation();
  const [editAnecdotes, { status: editStatus }] = useEditAnecdotesMutation();
  const [plan, setPlan] = useState(mode === "new" ? validatePlan() : "active");
  const defaultValues = {
    title: mode === "edit" ? title : "",
    desc: mode === "edit" ? desc : "",
    visibility: "dist",
  };
  const who = findWho(lg_id);
  const { handleSubmit, control, formState } = useForm({
    mode: "onChange",
    defaultValues,
  });

  //-----------------------Declare Functions Here-----------------------------//

  const formatData = (data) => {
    let plan = validatePlan(typ, "none");
    if (plan === "upgrade") {
      setPlan(plan);
    } else {
      if (mode === "new") {
        postAnecdotes({
          ...data,
          lg_id: lg_id,
          typ: typ,
        }).then(() => close(false));
      } else if (mode === "edit") {
        editAnecdotes({
          ...data,
          lg_id: lg_id,
          typ: typ,
          fstr: fstr,
          id: id,
        }).then(() => close(false));
      }
    }
  };
  let enablebutton =
    mode === "new"
      ? !formState.isValid
      : !(formState.isDirty && formState.isValid);

  // do not delete the below variable
  let dummy = {
    disable: enablebutton,
    isValid: formState.isValid,
    isDirty: formState.isDirty,
  };

  return (
    <>
      {plan === "active" && (
        <CustomDialog
          open={open}
          close={close}
          title={
            <Stack
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
              spacing={1}
              direction={"row"}
            >
              <CustomAvatar src={who?.readURL} />
              <Box>
                <Typography
                  variant="h6"
                  component={"div"}
                  align="center"
                  sx={{ fontSize: "22px", fontWeight: 500, color: "#333333" }}
                >
                  {who?.name}
                </Typography>
                <Typography
                  variant="caption"
                  component={"div"}
                  sx={{ fontSize: "14px" }}
                >
                  {t(`button.${findEventType(typ).title}`, {
                    count: 1,
                  })}
                </Typography>
              </Box>
            </Stack>
          }
          action={
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <LoadingButton
                variant="contained"
                disabled={enablebutton}
                onClick={handleSubmit((data) => formatData(data))}
                loading={postStatus === "pending" || editStatus === "pending"}
              >
                <Typography>
                  {t(`button.${mode === "new" ? "create" : "edit"}`)}
                </Typography>
              </LoadingButton>
            </Box>
          }
        >
          <Card sx={{ width: "100%", p: 1 }}>
            <Stack spacing={2}>
              <Controller
                name={"title"}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label={t("inputField.title")}
                    type={"text"}
                    inputProps={{
                      maxLength: 46,
                    }}
                    required={true}
                    error={fieldState.error ? true : false}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    sx={{ width: "100%" }}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: t("error.fieldIsRequired", {
                      param1: t("inputField.title"),
                    }),
                  },
                  maxLength: {
                    value: 45,
                    message: t("error.maxCharacters", {
                      param1: 45,
                    }),
                  },
                }}
              />
              <Controller
                name={"desc"}
                control={control}
                render={({ field, fieldState }) => (
                  <CustomTextArea
                    onChange={field.onChange}
                    error={fieldState.error}
                    label={t("inputField.description")}
                    value={field.value}
                    required={true}
                    maxLength={251}
                    lg_id={lg_id}
                  />
                )}
                rules={{
                  validate: {
                    length: (value) =>
                      value.blocks[0].text.length <= 250 ||
                      t("error.maxCharacters", {
                        param1: 250,
                      }),
                    required: (value) =>
                      validateRichText(
                        value.blocks[0].text,
                        formState.isDirty
                      ) === true ||
                      t("error.fieldIsRequired", {
                        param1: t("inputField.description"),
                      }),
                  },
                }}
              />
            </Stack>
          </Card>
        </CustomDialog>
      )}
      {plan === "upgrade" && (
        <>
          <UpgradePromt
            open={plan === "upgrade"}
            close={() => {
              close(false);
            }}
          />
        </>
      )}
    </>
  );
};
