import React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import { styled } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";

const StyledCard = styled(Card)(() => ({
  maxWidth: "360px",
  width: "100%",
  borderRadius: 1,
  transition: "0.3s",
  boxShadow: "0px 14px 80px rgba(34, 35, 58, 0.2)",
  position: "relative",
  overflow: "initial",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  textAlign: "center",
  paddingLeft: 8,
  paddingRight: 8,
  background:
    "linear-gradient(34deg, rgba(55,16,83,1) 0%, rgba(162,73,190,1) 29%, rgba(33,16,83,1) 92%)",
}));
const CardMediaMedia = styled(CardMedia)(({ theme: { breakpoints } }) => ({
  flexShrink: 0,
  width: "30%",
  paddingTop: "30%",
  marginLeft: "auto",
  marginRight: "auto",
  [breakpoints.up("sm")]: {
    marginRight: "initial",
  },
}));
const TypographyOverline = styled(Typography)(() => ({
  lineHeight: 2,
  color: "#ffffff",
  fontSize: "1rem",
  opacity: 1,
}));
const TypographyHeading = styled(Typography)(() => ({
  fontWeight: 600,
  color: "#ffffff",
  letterSpacing: 0.5,
}));
const StyledButton = styled(Button)(() => ({
  backgroundColor: "rgba(255, 255, 255, 0.15)",
  color: "#ffffff",
  textTransform: "none",
  "&:hover": {
    backgroundColor: "rgba(255, 255, 255, 0.32)",
  },
}));
export function UpgradePromt({ open, close = () => {} }) {
  //-----------------------Declare Hooks Here-----------------------------//

  const navigate = useNavigate();
  return (
    <Dialog open={open} onClose={close}>
      <StyledCard>
        <CardContent>
          <TypographyHeading>{t("text.updatePromtHeading")}</TypographyHeading>
          <TypographyOverline>{t("text.updatePromtBody")}</TypographyOverline>
          <StyledButton onClick={() => navigate("/upgrade")}>
            {t("button.buyInk")}
          </StyledButton>
        </CardContent>
      </StyledCard>
    </Dialog>
  );
}
