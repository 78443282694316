import React, { useEffect, useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { useTranslation } from "react-i18next";
import { getLanguageFromLocale } from "../../utils/functions";
import { useLazyUpdateLanguageQuery } from "../../redux/slice/updateLanguage";
import { useDispatch } from "react-redux";
import { updatePreferedLanguage } from "../../redux/slice/authentication";
import { somethingWentWrong } from "../../utils/constants";
import CircularProgress from "@mui/material/CircularProgress";
import { setAlert } from "../../redux/slice/alert";

export const ChangeLanguage = ({ hitApi = false }) => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(undefined);
  const [updateLanguage] = useLazyUpdateLanguageQuery();
  useEffect(() => {
    fetchSupportedLanguages();
  }, []);
  function fetchSupportedLanguages() {
    setLoading(true);
    fetch("https://stor1.famscape.com/openstore/textfiles/language.json")
      .then((response) => response.json())
      .then((response) => {
        setLoading(false);
        setData(response);
      })
      .catch(() => {
        setLoading(false);
        dispatch(setAlert(somethingWentWrong));
      });
  }

  const handleLanguageChange = (lang) => {
    i18n.changeLanguage(getLanguageFromLocale(lang));
    dispatch(updatePreferedLanguage(lang));
    if (hitApi) {
      updateLanguage(lang);
    }
  };
  return (
    <>
      <Stack spacing={1}>
        {!loading ? (
          <>
            {data ? (
              <>
                {Object.keys(data).map((item) => (
                  <Button
                    sx={{
                      color: "#333333",
                      display: "flex",
                      justifyContent: "flex-start",
                    }}
                    onClick={() => {
                      handleLanguageChange(item);
                    }}
                  >
                    {data[item]}
                  </Button>
                ))}
              </>
            ) : null}
          </>
        ) : (
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress />
          </Box>
        )}
      </Stack>
    </>
  );
};
