import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { useLocation, useNavigate } from "react-router-dom";
import HomeIcon from "@mui/icons-material/Home";
import ShareIcon from "@mui/icons-material/Share";
import SearchIcon from "@mui/icons-material/Search";
import HandshakeIcon from "@mui/icons-material/Handshake";
import { t } from "i18next";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import { useSelector } from "react-redux";
import { CreateNewEvent } from "../molecules/CreateNewEvent";
import Badge from "@mui/material/Badge";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";

export const AppBarMenuItems = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  const navigate = useNavigate();
  const location = useLocation();
  const [value, setValue] = useState("");
  const [open, setOpen] = useState(false);
  const [showLabel, setShowLabel] = useState(true);
  const { device } = useSelector((state) => state.config);
  const { userStatus } = useSelector((state) => state.config);
  const { relReqNotification } = useSelector((state) => state.notification);
  useEffect(() => {
    handleChange();
  }, [location.pathname]);
  useEffect(() => {
    handleWidthChanges();
    window.addEventListener("resize", handleWidthChanges);
  }, []);

  //-----------------------Declare Functions Here-----------------------------//

  const handleChange = () => {
    switch (location.pathname) {
      case "/":
        setValue("home");
        return;
      case "/explorer":
        setValue("explore");
        return;
      case "/search":
        setValue("search");
        return;
      case "/relationrequest":
        setValue("relationrequest");
        return;
      case "/alert":
        setValue("alert");
        return;
      default:
        setValue("");
        return;
    }
  };
  const handleWidthChanges = () => {
    if (window.innerWidth > 900) {
      setShowLabel(true);
    } else {
      setShowLabel(false);
    }
  };
  return (
    <Box sx={{ width: !showLabel ? "100%" : "unset" }}>
      <Tabs
        value={value}
        textColor="primary"
        indicatorColor={null}
        aria-label="secondary tabs example"
        sx={{
          width: "100%",
          ".MuiTabs-flexContainer": {
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          },
          ".Mui-selected p": { fontWeight: 600 },
        }}
      >
        <Tab
          value="home"
          sx={{ minWidth: 36 }}
          onClick={() => navigate("/")}
          icon={<HomeIcon />}
          iconPosition="start"
          label={showLabel ? <Typography>{t("button.home")}</Typography> : null}
        />
        <Tab
          onClick={() => navigate("/explorer")}
          value="explore"
          sx={{ minWidth: 36 }}
          icon={<ShareIcon />}
          iconPosition="start"
          label={
            showLabel ? <Typography>{t("button.explore")}</Typography> : null
          }
        />
        {device === "sm" && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Button
              sx={{
                height: "fit-content",
                minWidth: 0,
                p: 0,
                borderRadius: "50%",
              }}
              disabled={userStatus === "inactive"}
              variant="contained"
              onClick={() => setOpen(true)}
            >
              <AddIcon sx={{ fontSize: "32px" }} />
            </Button>
          </Box>
        )}
        <Tab
          value="search"
          sx={{ minWidth: 36 }}
          onClick={() => navigate("/search")}
          icon={<SearchIcon />}
          iconPosition="start"
          label={
            showLabel ? <Typography>{t("button.search")}</Typography> : null
          }
        />
        <Tab
          value="relationrequest"
          sx={{ minWidth: 36 }}
          onClick={() => navigate("/relationrequest")}
          icon={
            <Badge badgeContent={Number(relReqNotification)} color="error">
              <HandshakeIcon />
            </Badge>
          }
          iconPosition="start"
          label={
            showLabel ? (
              <Typography>{t("button.relationRequest")}</Typography>
            ) : null
          }
        />{" "}
        {device !== "sm" && (
          <Tab
            value="alert"
            sx={{ minWidth: 36 }}
            onClick={() => navigate("/alert")}
            icon={
              <Badge badgeContent={0} color="error">
                <NotificationsNoneOutlinedIcon />
              </Badge>
            }
            iconPosition="start"
            label={
              showLabel ? <Typography>{t("button.alert")}</Typography> : null
            }
          />
        )}
      </Tabs>
      {open && <CreateNewEvent open={open} close={() => setOpen(false)} />}
    </Box>
  );
};
