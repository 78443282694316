import { setAlert } from "../redux/slice/alert";
import { store } from "../redux/store";
import { baseURL } from "../utils/constants";

export const signupApi = async (FormData) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ ...FormData, prefLang: navigator.language }),
  };

  try {
    let api = fetch(`${baseURL}/signup`, requestOptions)
      .then((response) => response.json())
      .then((responseJSON) => {
        if (!responseJSON.error) {
          return { status: true, email: responseJSON.altMsg };
        }
        store.dispatch(setAlert({ ...responseJSON }));
        return false;
      });
    return api;
  } catch (e) {
    console.error(e);
    return false; // { error: true, message: helperText.errors.apiFailed };
  }
};
