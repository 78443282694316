import React from "react";
import { CustomDialog } from "../../UI/CustomDialog";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { t } from "i18next";
import { useForm, Controller } from "react-hook-form";
import { HelperIcon } from "../atoms/HelperIcon";
import { VisiblityHelperText } from "../atoms/VisiblityHelperText";
import { useStyles } from "../../utils/Theme";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector } from "react-redux";
import {
  autoCompleteDropdownValidation,
  formatFreeSoloChipInput,
  getLabelAndValue,
} from "../../utils/functions";
import {
  bloodGroupList,
  chronicIllnessList,
  physicalActivityList,
  smokingHabitList,
  visibilityTypes,
} from "../../utils/ListItems";
import { useEditLgAboutMutation } from "../../redux/slice/lgabout";

export const LgHealth = ({
  open,
  close = () => {},
  mode,
  blood_group = null,
  physical_activity = null,
  chronic_illness = null,
  hlth_visib = "dist",
  smoking = null,
  drinking = null,
  health_details = null,
  lg_id = "",
  lg_url = "",
  onNext = () => {},
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const defaultValues = {
    blood_group: getLabelAndValue(blood_group, bloodGroupList),
    physical_activity: getLabelAndValue(
      physical_activity,
      physicalActivityList
    ),
    chronic_illness: chronic_illness !== null ? chronic_illness.split("|") : [],
    smoking: getLabelAndValue(smoking, smokingHabitList),
    drinking: getLabelAndValue(drinking, smokingHabitList),
    health_details: health_details,
    hlth_visib: hlth_visib === null ? "dist" : hlth_visib,
    lg_id: lg_id,
    url: "lgHealth",
    lg_url: lg_url,
  };
  const { device } = useSelector((state) => state.config);
  const mobile = device === "xs" || device === "sm" || device === "md";
  const { handleSubmit, control, formState } = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const [editLg, { isLoading }] = useEditLgAboutMutation();
  const classes = useStyles();

  //-----------------------Declare Functions Here-----------------------------//

  const formatData = (data) => {
    data.blood_group = data.blood_group?.value
      ? data.blood_group.value
      : data.blood_group;
    data.physical_activity = data.physical_activity?.value
      ? data.physical_activity.value
      : data.physical_activity;
    data.chronic_illness =
      data.chronic_illness.length > 0 ? data.chronic_illness.join("|") : null;
    data.smoking = data.smoking?.value ? data.smoking.value : data.smoking;
    data.drinking = data.drinking?.value ? data.drinking.value : data.drinking;
    editLg(data)
      .then(() => {
        if (mode === "self" || mode === "new") {
          onNext();
        } else {
          close();
        }
      })
      .catch(() => close());
  };
  let dummy = {
    isValid: formState.isValid,
    isDirty: formState.isDirty,
  };

  return (
    <>
      <CustomDialog
        open={open}
        iconPlacement={mode === "self" ? "no" : "left"}
        close={close}
        title={
          <Typography
            variant="h6"
            sx={{ fontSize: "22px", fontWeight: 500, color: "#333333" }}
            align="center"
          >
            {t("text.editModeLgHealthTitle")}
          </Typography>
        }
        action={
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <LoadingButton
              variant="contained"
              loading={isLoading}
              disabled={!(formState.isDirty && formState.isValid)}
              onClick={handleSubmit((data) => formatData(data))}
            >
              {t("button.save")}
            </LoadingButton>
          </Box>
        }
      >
        <Card sx={{ width: "100%", p: 1 }}>
          <Stack spacing={2}>
            <Controller
              name={"blood_group"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  clearOnBlur
                  {...field}
                  value={field.value}
                  options={bloodGroupList.sort()}
                  autoHighlight={true}
                  freeSolo={false}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <Box>
                      <TextField
                        {...params}
                        label={t("inputField.bloodGroup")}
                        error={fieldState.error ? true : false}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    </Box>
                  )}
                  onChange={(_, data) => field.onChange(data)}
                  onInputChange={(_, data) => field.onChange(data)}
                />
              )}
              rules={{
                validate: (value) =>
                  autoCompleteDropdownValidation(value, bloodGroupList) ||
                  t("error.autoselectDropdownError"),
              }}
            />

            <Controller
              name={"physical_activity"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  clearOnBlur
                  {...field}
                  value={field.value}
                  options={physicalActivityList.sort()}
                  autoHighlight={true}
                  freeSolo={false}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <Box>
                      <TextField
                        {...params}
                        label={t("inputField.physicalActivity")}
                        error={fieldState.error ? true : false}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    </Box>
                  )}
                  onChange={(_, data) => field.onChange(data)}
                  onInputChange={(_, data) => field.onChange(data)}
                />
              )}
              rules={{
                validate: (value) =>
                  autoCompleteDropdownValidation(value, physicalActivityList) ||
                  t("error.autoselectDropdownError"),
              }}
            />

            <Controller
              name={"chronic_illness"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  clearOnBlur
                  {...field}
                  multiple
                  options={chronicIllnessList}
                  freeSolo={true}
                  onChange={(_, data) =>
                    field.onChange(formatFreeSoloChipInput(data))
                  }
                  onInputChange={(_, newInputValue) => {
                    const options = newInputValue.split(",");
                    if (options.length > 1) {
                      field.onChange(
                        field.value
                          .concat(options)
                          .map((x) => x.trim())
                          .filter((x) => x)
                      );
                    }
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      let getLabel = getLabelAndValue(
                        option,
                        chronicIllnessList
                      );
                      return (
                        <Chip
                          variant="outlined"
                          label={getLabel ? getLabel.label : option}
                          {...getTagProps({ index })}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("inputField.chronicIllness")}
                      error={fieldState.error ? true : false}
                      helperText={
                        fieldState.error
                          ? fieldState.error.message
                          : mobile
                          ? t("helperText.chipMobile")
                          : t("helperText.chipDesktop")
                      }
                      // placeholder="Favorites"
                    />
                  )}
                />
              )}
              rules={{
                validate: {
                  lengthOfEachChip: (value) => {
                    return (
                      value.every((item) => item.length <= 50) ||
                      `${t("error.noMoreChar")
                        .split(" ")
                        .slice(0, 6)
                        .join(" ")} 50 ${t("error.noMoreChar").split(" ")[6]}`
                    );
                  },
                  chipArrayCount: (value) =>
                    value.length <= 10 ||
                    `${t("error.maxChipEntryError").split(" ")[0]} 10 ${t(
                      "error.maxChipEntryError"
                    ).slice(5)}`,
                  checkforspecialCharacters: (value) =>
                    value.every((item) => item.includes("|") === false) ||
                    t("error.pipeOperatorError"),
                },
              }}
            />

            <Controller
              name={"smoking"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  clearOnBlur
                  {...field}
                  value={field.value}
                  options={smokingHabitList}
                  autoHighlight={true}
                  freeSolo={false}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <Box>
                      <TextField
                        {...params}
                        label={t("inputField.smoking")}
                        error={fieldState.error ? true : false}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    </Box>
                  )}
                  onChange={(_, data) => field.onChange(data)}
                  onInputChange={(_, data) => field.onChange(data)}
                />
              )}
              rules={{
                validate: (value) =>
                  autoCompleteDropdownValidation(value, smokingHabitList) ||
                  t("error.autoselectDropdownError"),
              }}
            />

            <Controller
              name={"drinking"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  clearOnBlur
                  {...field}
                  value={field.value}
                  options={smokingHabitList}
                  autoHighlight={true}
                  freeSolo={false}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.label}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <Box>
                      <TextField
                        {...params}
                        label={t("inputField.drinking")}
                        error={fieldState.error ? true : false}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                      />
                    </Box>
                  )}
                  onChange={(_, data) => field.onChange(data)}
                  onInputChange={(_, data) => field.onChange(data)}
                />
              )}
              rules={{
                validate: (value) =>
                  autoCompleteDropdownValidation(value, smokingHabitList) ||
                  t("error.autoselectDropdownError"),
              }}
            />

            <Controller
              name={"health_details"}
              control={control}
              defaultValue={defaultValues.health_details}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("inputField.AdditionalDetails")}
                  type={"text"}
                  inputProps={{
                    maxLength: 1001,
                  }}
                  error={fieldState.error ? true : false}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                  InputProps={{
                    inputComponent: TextareaAutosize,
                    endAdornment: (
                      <HelperIcon
                        helperText={
                          <Typography variant="body1">
                            {t("helperText.additionalDetails")}
                          </Typography>
                        }
                      />
                    ),
                  }}
                  sx={{ width: "100%" }}
                />
              )}
              rules={{
                maxLength: {
                  value: 1000,
                  message: t("error.maxCharacters", {
                    param1: 1000,
                  }),
                },
              }}
            />

            <Controller
              name={"hlth_visib"}
              control={control}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  classes={{
                    root: classes.textFieldRoot, // apply class here
                  }}
                  select={true}
                  label={t("inputField.Visiblity")}
                  value={field.value}
                  required={true}
                  sx={{ width: "100%" }}
                  InputProps={{
                    endAdornment: (
                      <HelperIcon helperText={<VisiblityHelperText />} />
                    ),
                  }}
                  error={fieldState.error ? true : false}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                >
                  {visibilityTypes.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: {
                  value: true,
                  message: t("error.fieldIsRequired"),
                },
              }}
            />
          </Stack>
        </Card>
      </CustomDialog>
    </>
  );
};
