import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import Backend from "i18next-http-backend";

// import { text } from "../src/text/english";
// let text = {};
// await fetch(`https://stor1.famscape.com/openstore/textfiles/english.json`)
//   .then((response) => response.json())
//   .then((response) => (text = response));
i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(Backend)
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    lng: "en",
    fallbackLng: "en",
    backend: {
      loadPath: `https://stor1.famscape.com/openstore/textfiles/{{lng}}.json`, // Change to your server URL
    },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    react: {
      useSuspense: false,
    },
    // resources: {
    //   en: {
    //     translation: {
    //       // here we will place our translations...
    //       display: { text },
    //     },
    //   },
    // },
  });

export default i18n;
