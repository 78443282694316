import React, { useState } from "react";
import { useUpdateReactionMutation } from "../../redux/slice/reaction";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Tooltip from "@mui/material/Tooltip";
import Stack from "@mui/material/Stack";
import {
  calculateReaction,
  formatProfileReactions,
} from "../../utils/functions";
import { motion } from "framer-motion";
import { emoji } from "../../utils/ListItems";
import { t } from "i18next";
import Typography from "@mui/material/Typography";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import Popper from "@mui/material/Popper";

export const Reaction = ({
  id,
  entry_type,
  lg_id,
  my_reaction,
  reaction_cnt,
  uniq_reaction_list,
  reaction,
  label = true,
  sx = {},
  profile = false,
  url = "",
  reactionSummary = [],
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const [updatePost] = useUpdateReactionMutation();
  const [anchorEl, setAnchorEl] = useState(null);
  let profileReaction = profile ? formatProfileReactions(reactionSummary) : [];
  let timer;

  //-----------------------Declare Hooks Here-----------------------------//

  const onReacted = (item) => {
    if (profile) {
      updatePost({
        id: "1",
        entry_type: "profile",
        lg_id: lg_id,
        reaction: item,
        uniq_reaction_list: uniq_reaction_list,
        url: url,
        delete:
          uniq_reaction_list.filter(
            (rec) => rec.reaction === item && rec.my_reaction === "yes"
          ).length > 0
            ? "yes"
            : "no",
      });
    } else
      updatePost({
        id,
        entry_type,
        lg_id,
        reaction: calculateReaction(item, my_reaction),
        my_reaction,
        reaction_cnt,
        uniq_reaction_list,
      });
    setAnchorEl(null);
  };
  const handleTimer = (event) => {
    setAnchorEl(event);
  };
  const onMouseEnter = (event) => {
    timer = setTimeout(handleTimer.bind(null, event.currentTarget), 900);
  };
  const onClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const onClose = () => {
    setAnchorEl(null);
  };

  const container = {
    hidden: { opacity: 0, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        staggerChildren: 0.05,
        type: "spring",
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
    },
  };

  return (
    <>
      <Button
        onMouseEnter={onMouseEnter}
        onClick={onClick}
        onMouseLeave={() => clearTimeout(timer)}
        sx={{ color: "grey.text", ...sx }}
      >
        <Stack direction={"row"} alignItems={"center"}>
          <>
            {my_reaction ? (
              <img
                src={emoji[my_reaction]}
                style={{ height: "28px", pointerEvents: "none" }}
              />
            ) : (
              <FavoriteBorderOutlinedIcon />
            )}
          </>
          {label && (
            <Typography variant="body1">
              {my_reaction ? t(`emoji.${my_reaction}`) : t("button.react")}
            </Typography>
          )}
        </Stack>
      </Button>
      <Popper
        id={Boolean(anchorEl) ? "simple-popover" : undefined}
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
      >
        <motion.div
          variants={container}
          initial="hidden"
          animate="visible"
          stiffness="100"
        >
          <ClickAwayListener onClickAway={onClose}>
            <Card
              sx={{
                display: "flex",
                alignItems: "center",
                width: "fit-content",
                maxWidth: "100%",
                px: 1,
                borderRadius: 5,
                overflow: "visible",
              }}
            >
              <Stack
                direction={"row"}
                spacing={1}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "fit-content",
                  maxWidth: profile ? "236px" : "100%",
                  px: 1,
                  borderRadius: 5,
                  overflow: "visible",
                  height: profile ? "250px" : "unset",
                  flexWrap: profile ? "wrap" : "nowrap",
                }}
              >
                {reaction &&
                  reaction.map((emoj, idx) => (
                    <motion.div
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                      key={idx}
                      variants={item}
                    >
                      <Tooltip title={t(`emoji.${emoj}`)}>
                        <Button
                          sx={{
                            minWidth: 0,
                            p: 0,
                            backgroundColor: profileReaction.includes(emoj)
                              ? "background.selected"
                              : "",
                          }}
                          disableRipple={true}
                          onClick={() => onReacted(emoj)}
                        >
                          <img
                            className="reactionbar"
                            style={{
                              height: "36px",
                              width: "36px",
                              "& :hover": { scale: 1.5 },
                            }}
                            src={emoji[emoj]}
                          />
                        </Button>
                      </Tooltip>
                    </motion.div>
                  ))}
              </Stack>
            </Card>
          </ClickAwayListener>
        </motion.div>
      </Popper>
    </>
  );
};
