import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import Cropper from "react-easy-crop";
import getCroppedImg from "./cropPicUtils/cropImage";
import { CustomDialog } from "../CustomDialog";
import { t } from "i18next";
import { findWho } from "../../utils/functions";

export const CropEasy = ({
  image,
  cropedImage,
  open,
  close = () => {},
  type = "profile",
  lg_id = "",
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const cropComplete = (_, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  };

  const cropImage = async () => {
    try {
      const { file } = await getCroppedImg(image, croppedAreaPixels, rotation);
      cropedImage(file);
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <CustomDialog
      title={
        <Typography
          variant="h6"
          sx={{ fontSize: "22px", fontWeight: 600, color: "#333333" }}
          align="center"
        >
          {t("inputField.change", {
            param1: t(
              type === "profile" ? "inputField.photo" : "inputField.coverPhoto"
            ),
          })}
        </Typography>
      }
      secondaryTitle={
        <Typography
          variant="caption"
          align="center"
          component={"div"}
          sx={{ fontSize: "14px" }}
        >
          {findWho(lg_id)?.name}
        </Typography>
      }
      open={open}
      close={close}
      action={
        <>
          <Box
            sx={{
              display: "flex",
              gap: 2,
              flexWrap: "wrap",
              width: "100%",
              justifyContent: "center",
            }}
          >
            <Button variant="contained" onClick={cropImage}>
              {t("button.update")}
            </Button>
          </Box>
        </>
      }
    >
      <>
        <Box
          sx={{
            background: "#333",
            position: "relative",
            height: 400,
            width: "auto",
            minWidth: { sm: 500 },
          }}
        >
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            aspect={type === "cover" ? 2 : 1}
            onZoomChange={setZoom}
            onRotationChange={setRotation}
            onCropChange={setCrop}
            onCropComplete={cropComplete}
          />
        </Box>
      </>
    </CustomDialog>
  );
};
