import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { t } from "i18next";

export const Footer = () => {
  return (
    <Grid item xs={12} sx={{ position: "relative", bottom: "0px" }}>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Stack
          direction={"row"}
          spacing={3}
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Link to="/about" target="_blank" style={{ textDecoration: "none" }}>
            <Typography variant="caption">{t("text.about")}</Typography>
          </Link>
          <Link
            to="/privacy"
            target="_blank"
            style={{ textDecoration: "none" }}
          >
            <Typography variant="caption">{t("text.privacyPolicy")}</Typography>
          </Link>
          <Link to="/terms" target="_blank" style={{ textDecoration: "none" }}>
            <Typography variant="caption">{t("text.terms")}</Typography>
          </Link>
        </Stack>
      </Box>
    </Grid>
  );
};
