import React from "react";
import Button from "@mui/material/Button";
import { t } from "i18next";
import Typography from "@mui/material/Typography";
import PaidIcon from "@mui/icons-material/Paid";
import { useNavigate } from "react-router-dom";

export const SubscriptionButton = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  const navigate = useNavigate();
  return (
    <Button
      color="inherit"
      sx={{ width: "100%" }}
      onClick={() => navigate("/upgrade")}
      startIcon={<PaidIcon />}
    >
      <Typography>{t("button.subscribtion")}</Typography>
    </Button>
  );
};
