import React, { useEffect, useState } from "react";
import { useGetAchievementsQuery } from "../../redux/slice/achievements";
import { useSelector } from "react-redux";
import { AchievementContainer } from "../organism/AchievementContainer";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import SortByAlphaRoundedIcon from "@mui/icons-material/SortByAlphaRounded";
import { NewAchievement } from "../forms/NewAchievement";
import { t } from "i18next";
import { HelperIcon } from "../atoms/HelperIcon";
import { findOwner, findWho, isBlocked } from "../../utils/functions";
import { GrannyTip } from "../../UI/GrannyTip";
import { MomentsSkleton } from "../Skleton/MomentsSkleton";

export const Achievements = ({ lgId }) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const { data, error, isLoading } = useGetAchievementsQuery(lgId);
  const { activeLgId } = useSelector((state) => state.profile);
  const { userStatus } = useSelector((state) => state.config);
  const [sort, setSort] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const [person, setPerson] = useState(undefined);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setShowButton(isBlocked(lgId) ? false : findOwner(lgId) ? true : false);
    setPerson(findWho(lgId));
  }, [lgId]);

  //-----------------------Declare Functions Here-----------------------------//

  const formatData = (data, sort) => {
    if (sort) return data;
    return data.toReversed();
  };

  return (
    <Stack spacing={1} sx={{ mt: 1 }}>
      <>
        <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-start",
            }}
          >
            {showButton && (
              <Button
                variant="contained"
                onClick={() => setOpen(true)}
                disabled={userStatus === "inactive"}
              >
                {t("button.addAchievements")}
              </Button>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {data && data?.achievementList.length > 0 && showButton && (
              <HelperIcon helperText={t("granny.emptyAchievementsP1")} />
            )}
          </Box>
        </Box>
        {open && (
          <NewAchievement open={open} close={setOpen} lg_id={activeLgId} />
        )}
        <Divider
          textAlign="right"
          sx={{
            "&.MuiDivider-root::before": { width: "100%" },
            "&.MuiDivider-root::after": { width: 0 },
            "&.MuiDivider-wrapper": { pl: "4px", pr: "0 !important" },
          }}
        >
          {data && data?.achievementList.length > 1 && (
            <Tooltip
              title={sort ? t("text.sortDesending") : t("text.sortAscending")}
            >
              <IconButton sx={{ p: 0 }} onClick={() => setSort(!sort)}>
                <SortByAlphaRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
        </Divider>
        {isLoading &&
          Array(3)
            .fill()
            .map((_, idx) => <MomentsSkleton key={idx} />)}

        {!isLoading && data && data?.achievementList.length > 0 && (
          <>
            {formatData(data.achievementList, sort).map((item, idx) => (
              <div key={idx}>
                <AchievementContainer
                  lg_id={activeLgId}
                  {...item}
                  user_connect_to_lg={data.user_connect_to_lg}
                />
              </div>
            ))}
          </>
        )}
        {!isLoading && data && data?.achievementList.length === 0 && (
          <Box
            sx={{
              pb: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <GrannyTip
              message={
                <Typography variant="body1">
                  {showButton
                    ? t("granny.emptyAchievementsP1")
                    : t("text.noAchievement")}
                </Typography>
              }
              sx={{
                width: "80%",
              }}
            />
          </Box>
        )}
      </>
    </Stack>
  );
};
