import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import React, { useState } from "react";
import SignUpForm from "../forms/Signup";
import { t } from "i18next";
import VerifyEmailPromt from "../atoms/VerifyEmailPromt";
import GoogleAuthentication from "../atoms/GoogleAuthentication";

const SignUp = () => {
  //-----------------------Declare Hooks Here-----------------------------//
  const [verifyEmail, setVerifyEmail] = useState({ status: false, email: "" });
  return (
    <Stack
      spacing={2}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "520px",
        width: "100%",
      }}
    >
      {verifyEmail.status ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <VerifyEmailPromt verifyEmail={verifyEmail} />
        </Box>
      ) : (
        <Stack
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
          spacing={3}
        >
          <Box>
            <Typography variant="h5">{t("text.signup")}</Typography>
            {/* <h6>{t("text.signupHeader")}</h6> */}
          </Box>
          <Box>
            <GoogleAuthentication />
          </Box>
          <Box sx={{ display: "flex", width: "100%" }}>
            <Divider
              sx={{
                width: "100%",
                "&::before": { mt: "4px" },
                "&::after": { mt: "4px" },
              }}
            >
              {t("text.or")}
            </Divider>
          </Box>
          <SignUpForm verifyEmail={setVerifyEmail} />
        </Stack>
      )}
    </Stack>
  );
};
export default SignUp;
