import React from "react";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import { t } from "i18next";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import LockResetIcon from "@mui/icons-material/LockReset";
import TranslateIcon from "@mui/icons-material/Translate";
export const AccountSettings = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  const navigate = useNavigate();

  return (
    <Card sx={{ p: 1 }}>
      <Stack spacing={1}>
        <Card sx={{ backgroundColor: "background.dark" }}>
          <Button
            color="inherit"
            sx={{ width: "100%", justifyContent: "space-between" }}
            onClick={() => navigate("/settings/account/language")}
            endIcon={<NavigateNextIcon />}
          >
            <Stack spacing={1} direction={"row"}>
              <TranslateIcon />
              <Typography>{t("text.changeLanguage")}</Typography>
            </Stack>
          </Button>{" "}
          <Divider></Divider>
          <Button
            color="inherit"
            sx={{ width: "100%", justifyContent: "space-between" }}
            onClick={() => navigate("/settings/account/password")}
            endIcon={<NavigateNextIcon />}
          >
            <Stack spacing={1} direction={"row"}>
              <LockResetIcon />
              <Typography>{t("text.changePassword")}</Typography>
            </Stack>
          </Button>
        </Card>
      </Stack>
    </Card>
  );
};
