import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import React, { useState } from "react";
import CustomAvatar from "../../UI/CustomAvathar";
import { findWho, formatNotificationParams } from "../../utils/functions";
import { t } from "i18next";
import { useNavigate } from "react-router-dom";
export const NotificationPill = ({ sx = {}, data, type = "notification" }) => {
  const who = findWho(data?.target_lg_id);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const handleOnClick = () => {
    if (data?.action_type === "redirect") {
      navigate(
        `/${data?.action_url.split(".")[2].split("/").slice(1).join("/")}`
      );
    } else if (data?.action_type === "popup") {
      setOpen(true);
    }
  };

  return (
    <>
      <Card
        sx={{
          width: "100%",
          minHeight: "80px",
          backgroundColor:
            type === "notification" ? "rgba(1, 156, 222, 0.13)" : "#ffffff",
          p: 0.5,
          ...sx,
        }}
        onClick={handleOnClick}
      >
        <Stack spacing={1} direction={"row"}>
          {
            <Box sx={{ height: "45px", width: "45px" }}>
              <CustomAvatar
                src={who?.readURL}
                sx={{ height: "45px", width: "45px" }}
              />
            </Box>
          }
          <Box>
            <Stack>
              {data?.notif_title && (
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 600, color: "#333333" }}
                >
                  {t(
                    `${data?.notif_title}`,
                    formatNotificationParams(data?.notif_title_param)
                  )}
                </Typography>
              )}
              {data?.notif_msg && (
                <Typography
                  sx={{ fontSize: "14px", fontWeight: 400, color: "#666666" }}
                >
                  {t(
                    `${data?.notif_msg}`,
                    formatNotificationParams(data?.notif_msg_param)
                  )}
                </Typography>
              )}
            </Stack>
          </Box>
        </Stack>
        <Box
          sx={{ display: "flex", justifyContent: "flex-end", width: "100%" }}
        >
          {data?.action_btn_label && (
            <Button
              sx={{ p: 0, px: 0.5, fontSize: "12px" }}
              onClick={handleOnClick}
            >
              {t(`${data?.action_btn_label}`)}
            </Button>
          )}
        </Box>
      </Card>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <Card sx={{ backgroundColor: "transparent" }}>
          <div
            dangerouslySetInnerHTML={{
              __html: t(`${data?.action_pop_up}`),
            }}
          ></div>
        </Card>
      </Dialog>
    </>
  );
};
