import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { t } from "i18next";
import { Button } from "@mui/material";
import { StyledMenu, VisuallyHiddenInput } from "../utils/Theme";
import { CropEasy } from "./cropPic/cropPic";
import {
  useDeleteProfilePictureMutation,
  useUploadProfilePictureMutation,
} from "../redux/slice/profilePicture";
import { DeleteEntryPromt } from "../components/atoms/DeleteEntryPromt";
import { validatePlan } from "../utils/functions";
import { UpgradePromt } from "./UpgradePromt";

export const UploadPicButton = ({
  type = "profile",
  lg_id = "",
  fstr = "",
  src = "NOFILE",
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [deletePromt, setDeletePromt] = useState(false);
  const [plan, setPlan] = useState("active");
  const [uploadProfilePicture] = useUploadProfilePictureMutation();
  const [deleteProfilePicture] = useDeleteProfilePictureMutation();
  const open = Boolean(anchorEl);

  //-----------------------Declare Functions Here-----------------------------//

  const handleClick = (event) => {
    if (src !== "NOFILE") setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setSelectedImage(null);
    setDeletePromt(false);
  };

  const handleChoose = (value) => {
    if (src === "NOFILE") {
      let plan = validatePlan(
        type === "profile" ? "profpic" : "banner",
        type === "profile" ? "none" : "image"
      );
      setAnchorEl(null);
      if (plan === "active") {
        setSelectedImage(URL.createObjectURL(value.target.files[0]));
      } else {
        setPlan(plan);
      }
    } else {
      setAnchorEl(null);
      setSelectedImage(URL.createObjectURL(value.target.files[0]));
    }
  };
  const handleDelete = () => {
    setAnchorEl(null);
    setDeletePromt(true);
  };

  const onUpload = (file) => {
    uploadProfilePicture({
      file: file,
      lg_id: lg_id,
      fstr: fstr,
      url: type === "profile" ? "profPic" : "bannerPic",
    })
      .then(handleClose())
      .catch(handleClose());
  };

  const onDelete = () => {
    deleteProfilePicture({
      lg_id: lg_id,
      fstr: fstr,
      url: type === "profile" ? "delProfPic" : "delBannerPic",
    })
      .then(handleClose())
      .catch(handleClose());
  };

  return (
    <>
      <IconButton
        id="demo-customized-button"
        aria-controls={open ? "demo-customized-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        onClick={handleClick}
        component={"label"}
      >
        <CameraAltIcon />
        {src === "NOFILE" && (
          <VisuallyHiddenInput
            type={"file"}
            accept={"image/*"}
            onChange={handleChoose}
          />
        )}
      </IconButton>
      <StyledMenu anchorEl={anchorEl} open={open} onClose={handleClose}>
        <Button startIcon={<EditIcon />} component={"label"}>
          {t(`inputField.${src === "NOFILE" ? "add" : "change"}`, {
            param1: t("inputField.image"),
          })}{" "}
          <VisuallyHiddenInput
            type={"file"}
            accept={"image/*"}
            onChange={handleChoose}
          />
        </Button>
        {src !== "NOFILE" && (
          <Button
            startIcon={<DeleteForeverIcon style={{ color: "white" }} />}
            color="error"
            variant="contained"
            onClick={handleDelete}
          >
            {t("inputField.delete", {
              param1: t("inputField.image"),
            })}
          </Button>
        )}
      </StyledMenu>
      {plan === "upgrade" && (
        <>
          <UpgradePromt
            open={plan === "upgrade"}
            close={() => {
              setPlan(false);
            }}
          />
        </>
      )}
      <CropEasy
        open={Boolean(selectedImage)}
        image={selectedImage}
        close={handleClose}
        type={type}
        lg_id={lg_id}
        cropedImage={onUpload}
      />
      <DeleteEntryPromt
        onCancel={handleClose}
        onDelete={onDelete}
        open={deletePromt}
        close={handleClose}
        message={t("text.deleteConfirmation", {
          param1: t("inputField.photo"),
        })}
        type="error"
      />
    </>
  );
};
