import Typography from "@mui/material/Typography";
import { visibilityTypes } from "../../utils/ListItems";
import { useTranslation, Trans } from "react-i18next";

export const VisiblityHelperText = () => {
  const { t } = useTranslation();

  return (
    <>
      <Typography variant="body1">{t("helperText.Visibility")}</Typography>
      <ul>
        {visibilityTypes.map((item) => (
          <li>
            <Trans
              i18nKey={`helperText.${item.value}`}
              components={{ bold: <strong /> }}
            />
          </li>
        ))}
      </ul>
      <Typography variant="body1">{t("helperText.VisibilityNote")}</Typography>
    </>
  );
};
