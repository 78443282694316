import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import Toolbar from "@mui/material/Toolbar";
import { useSelector } from "react-redux";
import Container from "@mui/material/Container";
import { HideOnScroll } from "../atoms/HideOnScroll";
import { AppBarMenuItems } from "../atoms/AppBarMenuItems";
import AppDrawer from "../atoms/AppDrawer";
import CustomAvatar from "../../UI/CustomAvathar";
import { findSelf } from "../../utils/functions";
import { RightNavigation } from "../molecules/RightNavigation";
import { LeftNavigation } from "../molecules/LeftNavigation";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import { t } from "i18next";
import { assetSource } from "../../utils/ListItems";

export default function CustomAppBar({ children }) {
  //-----------------------Declare Hooks Here-----------------------------//

  const { device } = useSelector((state) => state.config);
  const { name } = useSelector((state) => state.profile);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  return (
    <>
      <Box sx={{ flexGrow: 1 }}>
        <HideOnScroll hide={device === "sm"}>
          <AppBar
            color="white"
            sx={{
              borderStyle: "solid",
              borderColor: "border.main",
              borderWidth: 0,
              borderBottomWidth: "thin",
              position: "fixed",
              top: 0,
              height: { xs: "unset", sm: "73px" },
            }}
          >
            <Toolbar>
              <Box
                sx={{ display: "flex", width: "100%", alignItems: "center" }}
              >
                {device === "sm" && (
                  <AppDrawer anchor={"left"}>
                    <LeftNavigation />
                  </AppDrawer>
                )}
                {device !== "sm" && (
                  <img
                    src={`${assetSource}/logo_logo`}
                    onClick={() => navigate("/")}
                    style={{ cursor: "pointer" }}
                  />
                )}
                {device === "sm" && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <Box sx={{ width: "100%" }}>
                      <Typography
                        align="center"
                        sx={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "#333333",
                        }}
                      >
                        {pathname.split("/")[1] === "profile"
                          ? name
                          : t(`pathname.${pathname.split("/")[1]}`)}
                        {pathname.split("/")[1] === "profile" && (
                          <Typography
                            align="center"
                            sx={{
                              fontSize: "12px",
                              fontWeight: 400,
                              color: "#666666",
                            }}
                          >
                            {t(`pathname.${pathname.split("/")[3]}`)}
                          </Typography>
                        )}
                      </Typography>
                    </Box>
                  </Box>
                )}
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    width: device === "sm" ? "unset" : "100%",
                  }}
                >
                  {device !== "sm" && <AppBarMenuItems />}
                  {device === "sm" && (
                    <IconButton onClick={() => navigate("/alert")}>
                      <NotificationsNoneOutlinedIcon />
                    </IconButton>
                  )}
                  <AppDrawer
                    anchor={"right"}
                    icon={<CustomAvatar src={findSelf()?.readURL} />}
                  >
                    <RightNavigation />
                  </AppDrawer>
                </Box>
              </Box>
            </Toolbar>
          </AppBar>
        </HideOnScroll>
        <Container
          sx={{
            p: { xs: "0px", sm: "0px", md: "0px", lg: "0px" },
            ml: { xs: "0px", sm: "10px", md: "auto", lg: "auto" },
            mr: { xs: "0px", sm: "10px", md: "auto", lg: "auto" },
            maxWidth: { sm: "95.5vw", md: "900px", lg: "1350px" },
            height: "100%",
            position: "relative",
            top: 72,
          }}
          fixed
        >
          {children}
        </Container>
        {device === "sm" && (
          <AppBar
            position="fixed"
            color="white"
            sx={{
              top: "auto",
              bottom: 0,
              borderStyle: "solid",
              borderColor: "border.main",
              borderWidth: 0,
              borderTopWidth: "thin",
            }}
          >
            <Toolbar sx={{ width: "100%" }}>
              <Box sx={{ width: "100%" }} alignItems={"center"}>
                {device === "sm" && <AppBarMenuItems />}
              </Box>
            </Toolbar>
          </AppBar>
        )}
      </Box>
    </>
  );
}
