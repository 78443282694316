import React from "react";
import { CustomDialog } from "../../UI/CustomDialog";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import { t } from "i18next";
import { useForm, Controller } from "react-hook-form";
import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  useUpdateDivorcedDateMutation,
  useUpdateWeddingDateMutation,
} from "../../redux/slice/weddingDivorce";

export const LgSpouse = ({
  open,
  close = () => {},
  spouse_lg_id = "",
  marriage_date = null,
  divorce_date = null,
  lg_id = "",
  lg_url = "",
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const defaultValues = {
    marriage_date: marriage_date,
    divorce_date: divorce_date,
  };
  const { handleSubmit, control, formState, getFieldState } = useForm({
    mode: "onChange",
    defaultValues,
  });
  const [updateWeddingDate, { isLoading: weddingLoading }] =
    useUpdateWeddingDateMutation();
  const [updateDivorcedDate, { isLoading: divorceLoading }] =
    useUpdateDivorcedDateMutation();
  //-----------------------Declare Functions Here-----------------------------//
  dayjs.extend(utc);

  const formatData = (data, type) => {
    if (type === "wedding") {
      updateWeddingDate({
        lg_url: lg_url,
        lg_id: lg_id,
        spouse_lg_id: spouse_lg_id,
        event_dt: data.marriage_date,
      }).then(() => close());
    } else {
      updateDivorcedDate({
        lg_url: lg_url,
        lg_id: lg_id,
        spouse_lg_id: spouse_lg_id,
        event_dt: data.marriage_date,
      }).then(() => close);
    }
  };
  // do not delete this variable
  let dummy = {
    isValid: formState.isValid,
    isDirty: formState.isDirty,
  };

  return (
    <>
      <CustomDialog
        open={open}
        close={close}
        title={
          <Typography
            variant="h6"
            sx={{ fontSize: "22px", fontWeight: 500, color: "#333333" }}
            align="center"
          >
            {t("text.editModeLgPersonalTitle")}
          </Typography>
        }
      >
        <Card sx={{ width: "100%", p: 1 }}>
          <Stack spacing={2}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name={"marriage_date"}
                control={control}
                render={({ field, fieldState }) => (
                  <DatePicker
                    {...field}
                    onChange={(e) => {
                      field.onChange(dayjs(e).utc(true).format());
                    }}
                    onAccept={(e) => {
                      field.onChange(dayjs(e).utc(true).format());
                    }}
                    label={t("inputField.weddingDate")}
                    maxDate={dayjs()}
                    inputFormat="dd/mm/yyyy"
                    value={field.value ? dayjs(field.value) : null}
                    renderInput={(params) => (
                      <TextField
                        sx={{ width: "100%" }}
                        {...params}
                        error={fieldState.error ? true : false}
                        required={true}
                        helperText={
                          fieldState.error
                            ? fieldState.error.message
                            : t("texts.useApproxDate")
                        }
                      />
                    )}
                  />
                )}
                rules={{
                  validate: (value) =>
                    new Date(value) <= new Date() || t("error.dateError"),
                }}
              />
            </LocalizationProvider>
            <LoadingButton
              sx={{ width: "100%" }}
              variant="contained"
              disabled={!getFieldState("marriage_date").isDirty}
              loading={weddingLoading}
              onClick={handleSubmit((data) => formatData(data, "wedding"))}
            >
              {t("inputField.update", {
                param1: t("inputField.weddingDate"),
              })}
            </LoadingButton>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <Controller
                name={"divorce_date"}
                control={control}
                render={({ field, fieldState }) => (
                  <DatePicker
                    {...field}
                    onChange={(e) => {
                      field.onChange(dayjs(e).utc(true).format());
                    }}
                    onAccept={(e) => {
                      field.onChange(dayjs(e).utc(true).format());
                    }}
                    label={t("inputField.divorceDate")}
                    maxDate={dayjs()}
                    inputFormat="dd/mm/yyyy"
                    value={field.value ? dayjs(field.value) : null}
                    renderInput={(params) => (
                      <TextField
                        sx={{ width: "100%" }}
                        {...params}
                        error={fieldState.error ? true : false}
                        required={true}
                        helperText={
                          fieldState.error
                            ? fieldState.error.message
                            : t("text.useApproxDate")
                        }
                      />
                    )}
                  />
                )}
                rules={{
                  validate: (value) =>
                    new Date(value) <= new Date() || t("error.dateError"),
                }}
              />
            </LocalizationProvider>
            <LoadingButton
              sx={{ width: "100%" }}
              color="error"
              variant="contained"
              loading={divorceLoading}
              disabled={!getFieldState("divorce_date").isDirty}
              onClick={handleSubmit((data) => formatData(data, "divorced"))}
            >
              {t("inputField.update", {
                param1: t("inputField.divorceDate"),
              })}
            </LoadingButton>
          </Stack>
        </Card>
      </CustomDialog>
    </>
  );
};
