import React, { useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { TabPanel } from "../atoms/TabPanel";
import { t } from "i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { getTabFromTabIndex, getTabIndexfromTab } from "../../utils/functions";
import { LifeEvent } from "./LifeEvent";
import { Moments } from "./Moments";
import { Achievements } from "./Achievements";
import { Wish } from "./Wish";
import { Anecdotes } from "./Anecdotes";
import { Wisdom } from "./Wisdom";
import { Announcement } from "./Announcement";
import { LgAboutView } from "./LgAboutView";
import { LgAboutSkleton } from "../Skleton/LgAboutSkleton";

export const ProfileTabs = ({ loading, lgId, data = "" }) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const location = useLocation();
  const splitUrl = location.pathname.split("/");
  const navigate = useNavigate();
  const theme = useTheme();
  const [value, setValue] = useState(getTabIndexfromTab(splitUrl[3]));
  useEffect(() => {
    let splitUrl = location.pathname.split("/");
    setValue(getTabIndexfromTab(splitUrl[3]));
  }, [location]);

  //-----------------------Declare Functions Here-----------------------------//

  const handleChange = (event, newValue) => {
    setValue(newValue);
    navigate(`/${splitUrl[1]}/${splitUrl[2]}/${getTabFromTabIndex(newValue)}`);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
    navigate(`/${splitUrl[1]}/${splitUrl[2]}/${getTabFromTabIndex(index)}`);
  };

  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };

  return (
    <Box>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="full width tabs example"
        >
          <Tab label={t("button.about")} {...a11yProps(0)} />

          <Tab label={t("button.lifeEvents")} {...a11yProps(1)} />
          <Tab label={t("button.frozenMoments")} {...a11yProps(2)} />
          <Tab label={t("button.achievements")} {...a11yProps(3)} />
          <Tab label={t("button.loveAndWishes")} {...a11yProps(4)} />
          <Tab label={t("button.passDowns")} {...a11yProps(5)} />
          <Tab label={t("button.anecdotes")} {...a11yProps(6)} />
          <Tab label={t("button.announcements")} {...a11yProps(7)} />
        </Tabs>
      </AppBar>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          {!loading ? <LgAboutView data={data} /> : <LgAboutSkleton />}
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          {!loading ? <LifeEvent lgId={lgId} /> : null}
        </TabPanel>
        <TabPanel value={value} index={2} dir={theme.direction}>
          {!loading ? <Moments lgId={lgId} /> : null}
        </TabPanel>
        <TabPanel value={value} index={3} dir={theme.direction}>
          {!loading ? <Achievements lgId={lgId} /> : null}
        </TabPanel>
        <TabPanel value={value} index={4} dir={theme.direction}>
          {!loading ? <Wish lgId={lgId} /> : null}
        </TabPanel>
        <TabPanel value={value} index={5} dir={theme.direction}>
          {!loading ? <Wisdom lgId={lgId} /> : null}
        </TabPanel>
        <TabPanel value={value} index={6} dir={theme.direction}>
          {!loading ? <Anecdotes lgId={lgId} /> : null}
        </TabPanel>
        <TabPanel value={value} index={7} dir={theme.direction}>
          {!loading ? <Announcement lgId={lgId} /> : null}
        </TabPanel>
      </SwipeableViews>
    </Box>
  );
};
