import React, { useState } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import Slide from "@mui/material/Slide";
import { updateHomeFeedNudgeTimer } from "../../redux/slice/authentication";
import { getTimeDifference } from "../../utils/functions";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Autoplay } from "swiper/modules";
import "swiper/css";
import { welcomepromtImage } from "../../utils/ListItems";
import { CustomDialogSwipeUp } from "../../UI/CustomDialogSwipeUp";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const HomeFeedWelcomPromt = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  const { nudgePost } = useSelector((state) => state.notification);
  const { mode, currentPage } = useSelector((state) => state.createLg);
  const { homeFeedNudgeTimer } = useSelector((state) => state.authentication);

  const [open, setOpen] = useState(
    mode === null &&
      currentPage === null &&
      (Number(nudgePost.nudgeAddRelations) === 1 ||
        Number(nudgePost.nudgeNewPost) === 1 ||
        Number(nudgePost.nudgeProfileDetails) === 1) &&
      (homeFeedNudgeTimer
        ? getTimeDifference(new Date(), homeFeedNudgeTimer).days > 1
        : true)
  );

  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(updateHomeFeedNudgeTimer(new Date().toISOString()));
    setOpen(false);
  };

  const IntroCard = ({ value }) => {
    let cardStyles = {
      width: "90%",
      boxShadow: "1px 4px 4px 2px #00000040",
      backgroundColor: "#fff",
    };
    return (
      <Box
        sx={{ width: "100%", display: "flex", justifyContent: "center", p: 1 }}
      >
        <Card sx={cardStyles}>
          <Box sx={{ width: "100%", maxHeight: "250px" }}>
            <img
              src={welcomepromtImage[value]}
              style={{ width: "100%", maxHeight: "250px" }}
            />
          </Box>
          <Stack sx={{ p: 1, maxHeight: "120px" }} spacing={1}>
            {value === 0 && (
              <>
                <Typography sx={{ fontSize: "16px", color: "#666666" }}>
                  {t("text.homeFeedPromtP3")}
                </Typography>
              </>
            )}
            {value === 1 && (
              <>
                <Typography sx={{ fontSize: "16px", color: "#666666" }}>
                  {t("text.homeFeedPromtP2")}
                </Typography>
              </>
            )}{" "}
            {value === 2 && (
              <>
                <Typography sx={{ fontSize: "16px", color: "#666666" }}>
                  {t("text.homeFeedPromtP4")}
                </Typography>
              </>
            )}
          </Stack>
        </Card>
      </Box>
    );
  };
  return (
    <>
      <CustomDialogSwipeUp
        open={open}
        TransitionComponent={Transition}
        close={handleClose}
        title={
          <Typography
            sx={{ fontSize: "18px", fontWeight: 600, color: "#333333" }}
          >
            {t("text.introHeading")}
          </Typography>
        }
        iconPlacement="right"
      >
        <Card id="welcome-promt" sx={{ width: "100%", p: 1 }}>
          <Stack spacing={1}>
            {
              <>
                <Typography sx={{ fontSize: "16px", color: "#333333" }}>
                  {t("text.homeFeedPromtP1")}
                </Typography>
              </>
            }
            <Box>
              <Box className="swiper-button image-swiper-button-next">
                <NavigateNextIcon />
              </Box>
              <Box className="swiper-button image-swiper-button-prev">
                <NavigateBeforeIcon />
              </Box>
              <Swiper
                navigation={{
                  nextEl: ".image-swiper-button-next",
                  prevEl: ".image-swiper-button-prev",
                  disabledClass: "swiper-button-disabled",
                }}
                loop={true}
                autoplay={{
                  delay: 3000, // 3 seconds between transitions
                  disableOnInteraction: false, // Continue autoplay after user interaction
                }}
                modules={[Navigation, Autoplay]}
                className="mySwiper"
                slidesPerView={1}
                spaceBetween={30}
                pagination={{
                  clickable: true,
                }}
              >
                {Array(3)
                  .fill()
                  .map((_, item) => (
                    <SwiperSlide key={item}>
                      <IntroCard value={item} />
                    </SwiperSlide>
                  ))}
              </Swiper>
            </Box>
            <Button variant="contained" onClick={handleClose}>
              {t("button.continueToFamscape")}
            </Button>
          </Stack>
        </Card>
      </CustomDialogSwipeUp>
    </>
  );
};
