import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { t } from "i18next";
import React from "react";
import { useNavigate } from "react-router-dom";
import CustomAvatar from "../../UI/CustomAvathar";
export const FamilyTreeNodePopup = ({
  gender,
  name,
  link,
  readURL,
  open,
  close = () => {},
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const navigate = useNavigate();
  return (
    <Dialog open={open} onClose={close}>
      <Card
        sx={{
          p: 1,
          width: { xs: "100%", sm: "350px" },
          minWidth: "200px",
        }}
      >
        <Stack
          spacing={1}
          sx={{
            width: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <CustomAvatar
              sx={{
                width: "120px",
                height: "120px",
                borderRadius: "8px",
              }}
              src={readURL}
            />
          </Box>
          <Card sx={{ width: "100%", p: 1 }}>
            <Stack spacing={1} sx={{ width: "100%" }}>
              <Typography
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "20px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >{`${name}`}</Typography>
              <Button
                variant="contained"
                onClick={() => navigate(`/profile/${link.split("/")[4]}/about`)}
              >
                {t("text.visitProfile")}
              </Button>
            </Stack>
          </Card>
        </Stack>
      </Card>
    </Dialog>
  );
};
