import Button from "@mui/material/Button";
import React from "react";
import { CustomDialogSwipeUp } from "../../UI/CustomDialogSwipeUp";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { t } from "i18next";
import { findEventType } from "../../utils/functions";

export const DeleteEntryPromt = ({
  onDelete,
  onCancel,
  message,
  open,
  close = () => {},
  title = t("text.delete"),
  type = "info",
  buttonType = "dual",
  dualButtonLabel1 = t("button.no"),
  dualButtonLabel2 = t("button.yes"),
  singleButtonLabel = t("button.ok"),
  singleButtonOnClick = () => {},
}) => {
  //-----------------------Declare function Here-----------------------------//

  const handleDelete = () => {
    onDelete();
    close(false);
  };
  const eventType = findEventType(type);
  return (
    <>
      <CustomDialogSwipeUp
        open={open}
        close={close}
        title={title}
        color={eventType?.color}
        fontColor={eventType?.fontColor}
        icon={eventType?.icon}
        sx={{ p: 0 }}
      >
        <Stack spacing={1}>
          <Box
            sx={{
              p: 1,
              minHeight: "80px",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
            }}
          >
            {message}
          </Box>
          {buttonType === "dual" ? (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                borderTopStyle: "solid",
                borderTopColor: "border.main",
                borderTopWidth: "thin",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  borderRightStyle: "solid",
                  borderRightColor: "border.main",
                  borderRightWidth: "thin",
                }}
              >
                <Button
                  onClick={() => onCancel(false)}
                  // variant="outlined"
                  sx={{
                    width: "100%",
                    borderRadius: 0,
                    borderBottomLeftRadius: 8,
                    color: "#123984",
                    fontWeight: 600,
                  }}
                >
                  {dualButtonLabel1}
                </Button>
              </Box>
              <Button
                onClick={handleDelete}
                // variant="outlined"
                sx={{
                  width: "100%",
                  borderRadius: 0,
                  borderBottomRightRadius: 8,
                  color: "#123984",
                  fontWeight: 600,
                }}
              >
                {dualButtonLabel2}
              </Button>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-evenly",
                borderTopStyle: "solid",
                borderTopColor: "border.main",
                borderTopWidth: "thin",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  borderRightStyle: "solid",
                  borderRightColor: "border.main",
                  borderRightWidth: "thin",
                }}
              >
                <Button
                  onClick={() => singleButtonOnClick()}
                  sx={{
                    width: "100%",
                    borderRadius: 0,
                    borderBottomLeftRadius: 8,
                    color: "#123984",
                    fontWeight: 600,
                  }}
                >
                  {singleButtonLabel}
                </Button>
              </Box>
            </Box>
          )}
        </Stack>
      </CustomDialogSwipeUp>
    </>
  );
};
