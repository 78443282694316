import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  findEventType,
  findWho,
  formatDate,
  isYoutubeLink,
} from "../../utils/functions";
import Highlighter from "react-highlight-words";
import { RichTextViewer } from "../../UI/RichTextViewer";
import { ReactionViewer } from "../molecules/ReactionViewer";
import Button from "@mui/material/Button";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import Stack from "@mui/material/Stack";
import { ReactionAndCommentBtn } from "../molecules/ReactionAndCommentBtn";
import { t } from "i18next";
import { CustomDialog } from "../../UI/CustomDialog";
import { MediaGrid } from "./MediaGrid";
import { AddComment } from "../molecules/AddComment";
import { Img } from "../../UI/Img";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { regularExpressions } from "../../utils/constants";
import CustomAvatar from "../../UI/CustomAvathar";

export const HomeFeedCommentCard = (props) => {
  const {
    comment_cnt,
    created_lg,
    created_lg_url,
    desc,
    dt,
    entry_created_by,
    file_type,
    id,
    my_reaction,
    reaction_cnt,
    readURL,
    title,
    type,
    uniq_reaction_list,
    updated_date,
    visibility,
    searchValue = "",
    user_connect_to_lg,
    lg_id,
    fstr,
    link,
    typ,
  } = props;
  //-----------------------Declare Hooks Here-----------------------------//

  const { userStatus } = useSelector((state) => state.config);
  const [expandedView, setExpandedView] = useState(false);
  const [commentFocus, setCommentFocus] = useState(false);
  const who = findWho(lg_id);

  //-----------------------Declare function Here-----------------------------//

  const onExpand = (value) => {
    if (value === "comment") {
      setCommentFocus(true);
    } else {
      setCommentFocus(false);
    }
    setExpandedView(true);
  };

  return (
    <Card
      sx={{
        width: "100%",
        p: 0.5,
      }}
    >
      <Stack spacing={0.75}>
        <Grid
          onClick={onExpand}
          container
          sx={{ display: "flex", alignItems: "center" }}
        >
          <Grid
            sx={{
              pl: { xs: 0, md: 1 },
              height: { xs: "fit-content", sm: "100%" },
              position: "relative",
            }}
            item
            xs={file_type === "NOFILE" ? 12 : 10}
            sm={file_type === "NOFILE" ? 12 : 10}
            md={file_type === "NOFILE" ? 12 : 10}
            lg={file_type === "NOFILE" ? 12 : 10}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  {(searchValue || title) && (
                    <Typography
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textWrap: "nowrap",
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "text.heading",
                      }}
                      onClick={onExpand}
                    >
                      {!searchValue ? (
                        title
                      ) : (
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={searchValue.split(" ")}
                          autoEscape={true}
                          textToHighlight={title}
                        />
                      )}
                    </Typography>
                  )}
                </Box>
              </Box>
              {dt && (
                <Typography variant="caption">{formatDate(dt)}</Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                {(searchValue || desc) && (
                  <Typography
                    onClick={onExpand}
                    component={"div"}
                    sx={{
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "text.body",
                    }}
                  >
                    {!searchValue ? (
                      <RichTextViewer
                        text={desc}
                        truncate={true}
                        truncateChar={150}
                      />
                    ) : (
                      <Highlighter
                        highlightClassName="YourHighlightClass"
                        searchWords={searchValue.split(" ")}
                        autoEscape={true}
                        textToHighlight={desc.blocks[0].text}
                      />
                    )}
                  </Typography>
                )}
              </Box>
              {(Boolean(Number(reaction_cnt)) ||
                Boolean(Number(comment_cnt))) && (
                <Stack
                  spacing={1}
                  direction={"row"}
                  sx={{ display: "flex", alignItems: "center", py: 1 }}
                >
                  {Boolean(Number(reaction_cnt)) && (
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <ReactionViewer
                        reaction_cnt={reaction_cnt}
                        uniq_reaction_list={uniq_reaction_list}
                        entry_id={id}
                        entry_type={typ}
                        lg_id={lg_id}
                      />
                      {Boolean(Number(comment_cnt)) && (
                        <Box
                          sx={{
                            borderRight: "solid",
                            borderRightWidth: "thin",
                            borderRightColor: "grey.text",
                            height: "10px",
                          }}
                        />
                      )}
                    </Stack>
                  )}
                  {Boolean(Number(comment_cnt)) && (
                    <Button
                      sx={{
                        minHeight: 0,
                        minWidth: 0,
                        p: 0,
                        color: "grey.text",
                      }}
                      onClick={onExpand}
                    >
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={0.25}
                        sx={{ display: "flex", alignItems: "center" }}
                      >
                        <Typography variant="caption" sx={{ fontSize: "14px" }}>
                          {comment_cnt}
                        </Typography>
                        <ChatBubbleOutlineIcon sx={{ fontSize: "14px" }} />
                      </Stack>
                    </Button>
                  )}
                </Stack>
              )}
            </Box>
          </Grid>
          {file_type !== "NOFILE" && (
            <Grid
              item
              xs={2}
              sm={2}
              md={2}
              lg={2}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                // backgroundColor: "black.main",
                borderRadius: 1,
                overflow: "hidden",
                maxHeight: "80px",
                maxWidth: "80px",
              }}
            >
              {file_type && (
                <Box sx={{ width: "100%" }}>
                  {file_type.split("/")[0] === "image" ? (
                    <Box
                      sx={{
                        //   height: "40px",
                        width: "100%",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Img
                        style={{
                          width: "100%", // isMobile ? "" : "100%",
                          height: "100%", // isMobile ? "256px" : "100%",
                          maxHeight: "100%", // isMobile ? "350px" : "256px",
                          objectFit: "contain",
                        }}
                        key={readURL}
                        loading="lazy"
                        src={readURL}
                        alt="achievement"
                        onClick={onExpand}
                      />
                    </Box>
                  ) : file_type.split("/")[0] === "video" ? (
                    <video
                      width="100%"
                      style={{ maxHeight: "80px" }}
                      key={readURL}
                      controls
                    >
                      <source src={readURL} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  ) : file_type.split("/")[0] === "audio" ? (
                    <Box sx={{ p: 1 }}>
                      <audio
                        controls
                        style={{
                          width: "100%",
                          borderBottomLeftRadius: "20px",
                          borderBottomRightRadius: "20px",
                        }}
                      >
                        <source src={readURL} type="audio/mpeg" />
                        Your browser does not support the video tag.
                      </audio>
                    </Box>
                  ) : file_type.split("/")[0] === "application" ? (
                    <Box sx={{ p: 1 }} onClick={onExpand}>
                      <iframe
                        width="100%"
                        height="80px"
                        src={readURL}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                        onClick={onExpand}
                      />
                    </Box>
                  ) : file_type.split("/")[0] === "NOFILE" && link ? (
                    <>
                      {isYoutubeLink(link) ? (
                        <iframe
                          style={
                            {
                              // borderBottomLeftRadius: "4px",
                              // borderBottomRightRadius: "4px",
                            }
                          }
                          width="100%"
                          height="80"
                          src={`https://www.youtube.com/embed/${
                            link.match(regularExpressions.isYoutubeLink)[1]
                          }`}
                          // frameBorder="0"
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                          allowFullScreen
                          title="Embedded youtube"
                        />
                      ) : (
                        <Box sx={{ p: 1 }}>
                          <Button
                            color="error"
                            sx={{ borderRadius: 1, width: "100%" }}
                            variant="outlined"
                            startIcon={<OpenInNewIcon />}
                            onClick={() => window.open(link, "_blank")}
                          >
                            Open Link{" "}
                          </Button>
                        </Box>
                      )}
                    </>
                  ) : null}
                </Box>
              )}
            </Grid>
          )}
        </Grid>
        {userStatus === "active" && (
          <ReactionAndCommentBtn
            id={id}
            entry_type={typ}
            lg_id={lg_id}
            my_reaction={my_reaction}
            reaction_cnt={reaction_cnt}
            uniq_reaction_list={uniq_reaction_list}
            reaction={findEventType(typ).reaction}
            onClick={onExpand}
          />
        )}
      </Stack>
      {expandedView && (
        <CustomDialog
          open={Boolean(expandedView)}
          close={setExpandedView}
          action={
            userStatus === "active" && (
              <AddComment
                lg_id={lg_id}
                entry_id={id}
                entry_type={typ}
                focusOnInput={commentFocus}
              />
            )
          }
          title={
            <Stack
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
              spacing={1}
              direction={"row"}
            >
              <CustomAvatar src={who?.readURL} />
              <Box>
                <Typography
                  variant="h6"
                  component={"div"}
                  align="center"
                  sx={{ fontSize: "22px", fontWeight: 500, color: "#333333" }}
                >
                  {who?.name}
                </Typography>
                <Typography
                  variant="caption"
                  component={"div"}
                  sx={{ fontSize: "14px" }}
                >
                  {t(`button.${findEventType(typ).title}`, {
                    count: 1,
                  })}
                </Typography>
              </Box>
            </Stack>
          }
        >
          {expandedView && <MediaGrid {...props} typ={typ} readURL={readURL} />}
        </CustomDialog>
      )}
    </Card>
  );
};
