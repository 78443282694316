import Box from "@mui/material/Box";
import Fade from "@mui/material/Fade";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import React from "react";
import { Link } from "react-router-dom";
import { t } from "i18next";
import { assetSource } from "../../utils/ListItems";

const VerifyEmailPromt = ({ verifyEmail }) => {
  return (
    <Box sx={{ pt: 7 }}>
      <Fade in={true} timeout={1000}>
        <center>
          <Box>
            <img src={`${assetSource}/logo_logoSmall`} alt="brokenImage" />
            <Stack spacing={2}>
              <Typography variant="h4">
                {t("text.emailVerificationH1")}
              </Typography>
              <Typography variant="body1">
                {t("text.emailVerificationP1")}
              </Typography>
              <Link to={`https://www.${verifyEmail.email.split("@")[1]}`}>
                {verifyEmail.email}
              </Link>
              <Typography variant="body1">
                {t("text.emailVerificationP2")}
              </Typography>
            </Stack>
          </Box>
        </center>
      </Fade>
    </Box>
  );
};
export default VerifyEmailPromt;
