import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { getLanguageFromLocale } from "./functions";
const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};
export const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );
  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowDimensions;
};

export const useGetCountries = () => {
  const [countries, setCountries] = useState();
  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then((response) => response.json())
      .then((data) => {
        setCountries(data); // Logs the country code (e.g., "US")
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }, []);
  return countries;
};
export const useContainerDimensions = (myRef) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const getDimensions = () => ({
      width: myRef.current.offsetWidth,
      height: myRef.current.offsetHeight,
    });

    const handleResize = () => {
      if (myRef.current) {
        setDimensions(getDimensions());
      }
    };

    if (myRef.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [myRef]);

  return dimensions;
};
export const useCenteredTree = (defaultTranslate = { x: 0, y: 0 }) => {
  const [translate, setTranslate] = useState(defaultTranslate);
  const [dimensions, setDimensions] = useState();
  const containerRef = useCallback((containerElem) => {
    if (containerElem !== null) {
      const { width, height } = containerElem.getBoundingClientRect();
      setDimensions({ width, height });
      setTranslate({ x: width / 2, y: height / 2 - 175 });
    }
  }, []);
  return [dimensions, translate, containerRef];
};

export const useChangeLanguage = (lang) => {
  const { i18n } = useTranslation();
  useEffect(() => {
    i18n.changeLanguage(getLanguageFromLocale(lang));
  }, [lang]);
};
