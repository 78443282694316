import React, { useEffect } from "react";
import { FamilyTree } from "../organism/FamilyTree";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { t } from "i18next";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export const Explore = () => {
  const { device } = useSelector((state) => state.config);
  const { lg_id, name } = useSelector((state) => state.familyTree);
  const navigate = useNavigate();
  useEffect(() => {
    if (!lg_id) navigate("/");
  }, [lg_id]);

  return (
    <Box>
      {device !== "sm" && (
        <Stack spacing={1.5}>
          <Card
            sx={{
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              py: 1,
            }}
          >
            <Typography
              sx={{
                lineHeight: 1.5,
                fontSize: "20px",
                fontWeight: 400,
                color: "#333333",
              }}
            >
              {name
                ? t("text.familyTree", {
                    param1: name.split(" ")[0],
                  })
                : t("text.emptyfamilyTree")}
            </Typography>
          </Card>
        </Stack>
      )}
      {device !== "sm" && <Divider></Divider>}
      <Card
        sx={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          height: "86vh",
          width: "100%",
        }}
      >
        {lg_id && <FamilyTree lg_id={lg_id} />}
      </Card>
    </Box>
  );
};
