import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  usrToken: "",
  usrId: "",
  usrEmail: "",
  isLoggedIn: false,
  usrName: "",
  notification: {
    permission: "default",
    lastPromt: "",
    eligible: false,
    fcmToken: "",
    time: new Date().toISOString(),
  },
  homeFeedNudgeTimer: "",
  language: navigator.language,
};

export const authenticationSlice = createSlice({
  name: "authentication",
  initialState,
  reducers: {
    updateUserToken: (state, action) => {
      state.usrToken = action.payload;
    },
    updateUserId: (state, action) => {
      state.usrId = action.payload;
    },
    updateUserEmail: (state, action) => {
      state.usrEmail = action.payload;
    },
    updateUserName: (state, action) => {
      state.usrName = action.payload;
    },
    updateIsLoggedIn: (state, action) => {
      state.isLoggedIn = action.payload;
    },
    updateNotificationPromt: (state, action) => {
      state.notification = { ...state.notification, ...action.payload };
    },
    updateHomeFeedNudgeTimer: (state, action) => {
      state.homeFeedNudgeTimer = action.payload;
    },
    updatePreferedLanguage: (state, action) => {
      state.language = action.payload;
    },
    resetAuthentication: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  updateUserToken,
  updateUserId,
  updateUserEmail,
  updateIsLoggedIn,
  resetAuthentication,
  updateUserName,
  updateNotificationPromt,
  updateHomeFeedNudgeTimer,
  updatePreferedLanguage,
} = authenticationSlice.actions;

export default authenticationSlice.reducer;
