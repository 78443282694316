import React, { useEffect, useState } from "react";
import { useGetLifeEventQuery } from "../../redux/slice/lifeEvent";
import { useSelector } from "react-redux";
import { LifeEventContainer } from "../organism/LifeEventContainer";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import SortByAlphaRoundedIcon from "@mui/icons-material/SortByAlphaRounded";
import { NewLifeEvent } from "../forms/NewLifeEvent";
import { t } from "i18next";
import { HelperIcon } from "../atoms/HelperIcon";
import { findOwner, findWho, isBlocked } from "../../utils/functions";
import { GrannyTip } from "../../UI/GrannyTip";
import { LifeEventSkleton } from "../Skleton/LifeEventSkleton";

export const LifeEvent = ({ lgId }) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const { data, error, isLoading } = useGetLifeEventQuery(lgId);
  const { activeLgId } = useSelector((state) => state.profile);
  const { userStatus } = useSelector((state) => state.config);
  const [sort, setSort] = useState(true);
  const [showButton, setShowButton] = useState(false);
  const [person, setPerson] = useState(undefined);
  const [open, setOpen] = useState(false);
  useEffect(() => {
    setShowButton(isBlocked(lgId) ? false : findOwner(lgId) ? true : false);
    setPerson(findWho(lgId));
  }, [lgId]);

  //-----------------------Declare Functions Here-----------------------------//

  const formatData = (data, sort) => {
    if (sort) return data.toSorted((a, b) => new Date(a.dt) - new Date(b.dt));
    return data.toSorted((a, b) => new Date(b.dt) - new Date(a.dt));
  };

  return (
    <Stack spacing={1} sx={{ mt: 1 }}>
      <>
        <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "flex-start",
            }}
          >
            {showButton && (
              <Button
                variant="contained"
                onClick={() => setOpen(true)}
                disabled={userStatus === "inactive"}
              >
                {t("button.addLifeEvent")}
              </Button>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {data && data?.lifeEventList.length > 0 && showButton && (
              <HelperIcon
                helperText={t("granny.emptyLifeEventP1", {
                  param1: person
                    ? person?.relation === "Self"
                      ? t("text.your")
                      : `${person?.name}`
                    : null,
                  param2: person
                    ? person.relation === "Self"
                      ? t("text.selfDiscovery")
                      : t("text.discovery")
                    : null,
                })}
              />
            )}
          </Box>
        </Box>
        {open && (
          <NewLifeEvent open={open} close={setOpen} lg_id={activeLgId} />
        )}
        <Divider
          textAlign="right"
          sx={{
            "&.MuiDivider-root::before": { width: "100%" },
            "&.MuiDivider-root::after": { width: 0 },
            "&.MuiDivider-wrapper": { pl: "4px", pr: "0 !important" },
          }}
        >
          {data && data?.lifeEventList.length > 1 && (
            <Tooltip
              title={sort ? t("text.sortDesending") : t("text.sortAscending")}
            >
              <IconButton sx={{ p: 0 }} onClick={() => setSort(!sort)}>
                <SortByAlphaRoundedIcon />
              </IconButton>
            </Tooltip>
          )}
        </Divider>
        {isLoading &&
          Array(3)
            .fill()
            .map((_, idx) => <LifeEventSkleton key={idx} />)}

        {!isLoading && data && data?.lifeEventList.length > 0 && (
          <>
            {formatData(data.lifeEventList, sort).map((item, idx) => (
              <div key={idx}>
                <LifeEventContainer
                  lg_id={activeLgId}
                  {...item}
                  user_connect_to_lg={data.user_connect_to_lg}
                />
              </div>
            ))}
          </>
        )}
        {!isLoading && data && data?.lifeEventList.length === 0 && (
          <Box
            sx={{
              pb: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <GrannyTip
              message={
                <Typography variant="body1">
                  {showButton
                    ? person?.relation === "Self"
                      ? t("granny.emptyLifeEventSelfP1", {
                          param1: t("text.your"),
                          param2: t("text.selfDiscovery"),
                        })
                      : t("granny.emptyLifeEventP1", {
                          param1: person?.name,
                          param2: t("text.discovery"),
                        })
                    : t("text.noLifeEvent")}
                </Typography>
              }
              sx={{
                width: "80%",
              }}
            />
          </Box>
        )}
      </>
    </Stack>
  );
};
