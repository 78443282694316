import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { t } from "i18next";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import LogoutIcon from "@mui/icons-material/Logout";
import { logoutApi } from "../../api/logoutApi";
import { detectOS } from "../../utils/functions";
import { mainApi } from "../../redux/slice/mainApi";
import { resetAuthentication } from "../../redux/slice/authentication";
import { resetAlert } from "../../redux/slice/alert";
import { resetConfig } from "../../redux/slice/configuration";
import { resetNotification } from "../../redux/slice/notification";
import { resetUserSummary } from "../../redux/slice/userSummary";
import { resetAddEvent } from "../../redux/slice/addevent";
import { resetUploadProgress } from "../../redux/slice/uploadProgress";
import { resetCreateLg } from "../../redux/slice/createLg";

export const LogoutButton = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  const dispatch = useDispatch();
  const { notification } = useSelector((state) => state.authentication);
  const [logout, setLogout] = useState(false);
  const formData = {
    fcmToken: notification.fcmToken,
    deviceType: detectOS(),
  };
  useEffect(() => {
    if (logout) reset();
  }, [logout]);
  const reset = () => {
    dispatch(mainApi.util.resetApiState());
    dispatch(resetAuthentication());
    dispatch(resetAlert());
    dispatch(resetConfig());
    dispatch(resetNotification());
    dispatch(resetUserSummary());
    dispatch(resetAddEvent());
    dispatch(resetUploadProgress());
    dispatch(resetCreateLg());
  };
  return (
    <Button
      variant="contained"
      color="error"
      sx={{ width: "100%" }}
      onClick={() => {
        if (notification?.fcmToken) logoutApi(formData).then(() => reset());
        else setLogout(true);
      }}
      startIcon={<LogoutIcon />}
    >
      <Typography>{t("button.logout")}</Typography>
    </Button>
  );
};
