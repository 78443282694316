import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import React, { useEffect } from "react";
import { ProfileBadgeCard } from "./ProfileBadgeCard";
import { NotificationPill } from "./NotificationPill";
import { useSelector } from "react-redux";
import { findSelf } from "../../utils/functions";
import { useLazyGetHomeFeedQuery } from "../../redux/slice/homeFeed";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

export const Trending = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  const { device } = useSelector((state) => state.config);
  const [trigger, { data }] = useLazyGetHomeFeedQuery();
  const profileNotification = data
    ? data?.alertList?.filter((item) => item.notif_catg === "family")
    : [];
  const tips = data
    ? data?.alertList?.filter((item) => item.notif_catg === "tip")
    : [];
  const self = findSelf();
  const navigate = useNavigate();
  useEffect(() => {
    trigger({}, true);
  }, []);
  return (
    <Box sx={{ mt: 1 }}>
      <Stack spacing={2}>
        <ProfileBadgeCard
          lgScore={self?.lg_score}
          engScore={self?.engagement_score}
          bagge={"beginer"}
        />
        {device === "lg" && profileNotification.length > 0 && (
          <Stack spacing={0.5}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <Typography
                sx={{
                  fontSize: "16px",
                  fontWeight: 600,
                  lineHeight: "18.7px",
                }}
              >
                {`${t("text.notification")} (${profileNotification.length})`}
              </Typography>
              <Typography
                sx={{
                  fontSize: "12px",
                  fontWeight: 400,
                  lineHeight: "14px",
                  "&:hover": {
                    textDecoration: "underline",
                    cursor: "pointer",
                  },
                }}
                onClick={() => navigate("/alert")}
              >
                {t("text.seeAll")}
              </Typography>
            </Box>
            {profileNotification?.slice(0, 2).map((item, idx) => (
              <div key={idx}>
                <NotificationPill data={item} />
              </div>
            ))}
          </Stack>
        )}
        {device === "lg" && tips.length > 0 && (
          <Stack spacing={1}>
            <Typography
              sx={{ fontSize: "16px", fontWeight: 600, lineHeight: "18.7px" }}
            >
              {t("text.tips")}
            </Typography>
            {tips.map((item, idx) => (
              <div key={idx}>
                <NotificationPill data={item} type="tips" />
              </div>
            ))}
          </Stack>
        )}
      </Stack>
    </Box>
  );
};
