import React from "react";
import { useGetReactionQuery } from "../../redux/slice/reaction";
import { CustomNameTag } from "../../UI/CustomNameTag";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import { emoji } from "../../utils/ListItems";
import Tooltip from "@mui/material/Tooltip";
import { t } from "i18next";
import { findDaysAndHours } from "../../utils/functions";
import { ReactionCountSkleton } from "../Skleton/ReactionCount";

export const ReactionCount = ({ lg_id, entry_id, entry_type }) => {
  const { data, isLoading } = useGetReactionQuery({
    lg_id,
    entry_id,
    entry_type,
  });
  return (
    <Box sx={{ minHeight: 250, px: 1 }}>
      {!isLoading ? (
        <Stack spacing={2}>
          {data &&
            data.reactionList.map((item, idx) => (
              <Box
                key={idx}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <CustomNameTag
                  src={item.readURL}
                  name={item.commenter_name}
                  link={item.commenter_link}
                  secondaryText={findDaysAndHours(item.comment_date)}
                />
                <Tooltip title={t(`emoji.${item.comment}`)}>
                  <img
                    style={{ height: "40px", width: "40px" }}
                    src={emoji[item.comment]}
                  />
                </Tooltip>
              </Box>
            ))}
        </Stack>
      ) : (
        <Stack spacing={2}>
          {Array(3)
            .fill()
            .map((_, idx) => (
              <ReactionCountSkleton key={idx} />
            ))}
        </Stack>
      )}
    </Box>
  );
};
