import React from "react";
import { CustomDialog } from "../../UI/CustomDialog";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { t } from "i18next";
import { useForm, Controller } from "react-hook-form";
import { HelperIcon } from "../atoms/HelperIcon";
import { VisiblityHelperText } from "../atoms/VisiblityHelperText";
import { useStyles } from "../../utils/Theme";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  bestQualitiesList,
  hobbiesList,
  musicList,
  sportsList,
  visibilityTypes,
  worstQualitiesList,
} from "../../utils/ListItems";
import { useSelector } from "react-redux";
import {
  formatFreeSoloChipInput,
  getLabelAndValue,
} from "../../utils/functions";
import { useEditLgAboutMutation } from "../../redux/slice/lgabout";

export const LgPersonality = ({
  open,
  close = () => {},
  mode,
  best_qual = null,
  worst_qual = null,
  persntly_add_details = null,
  hobbies = null,
  fav_food = null,
  fav_books = null,
  fav_sports = null,
  fav_music = null,
  persnlty_visib = "dist",
  lg_id = "",
  lg_url = "",
  onNext = () => {},
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const defaultValues = {
    best_qual: best_qual !== null ? best_qual.split("|") : [],
    worst_qual: worst_qual !== null ? worst_qual.split("|") : [],
    persntly_add_details: persntly_add_details,
    hobbies: hobbies !== null ? hobbies.split("|") : [],
    fav_food: fav_food !== null ? fav_food.split("|") : [],
    fav_books: fav_books !== null ? fav_books.split("|") : [],
    fav_sports: fav_sports !== null ? fav_sports.split("|") : [],
    fav_music: fav_music !== null ? fav_music.split("|") : [],
    persnlty_visib: persnlty_visib === null ? "dist" : persnlty_visib,
    lg_id: lg_id,
    url: "lgPersonality",
    lg_url: lg_url,
  };
  const { device } = useSelector((state) => state.config);
  const mobile = device === "xs" || device === "sm" || device === "md";
  const { handleSubmit, control, formState } = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const [editLg, { isLoading }] = useEditLgAboutMutation();
  const classes = useStyles();

  //-----------------------Declare Functions Here-----------------------------//

  const formatData = (data) => {
    data.best_qual =
      data.best_qual.length > 0 ? data.best_qual.join("|") : null;
    data.worst_qual =
      data.worst_qual.length > 0 ? data.worst_qual.join("|") : null;
    data.hobbies = data.hobbies.length > 0 ? data.hobbies.join("|") : null;
    data.fav_food = data.fav_food.length > 0 ? data.fav_food.join("|") : null;
    data.fav_books =
      data.fav_books.length > 0 ? data.fav_books.join("|") : null;
    data.fav_sports =
      data.fav_sports.length > 0 ? data.fav_sports.join("|") : null;
    data.fav_music =
      data.fav_music.length > 0 ? data.fav_music.join("|") : null;
    editLg(data)
      .then(() => {
        if (mode === "self" || mode === "new") {
          onNext();
        } else {
          close();
        }
      })
      .catch(() => close());
  };
  let dummy = {
    isValid: formState.isValid,
    isDirty: formState.isDirty,
  };

  return (
    <>
      <CustomDialog
        open={open}
        iconPlacement={mode === "self" ? "no" : "left"}
        close={close}
        title={
          <Typography
            variant="h6"
            sx={{ fontSize: "22px", fontWeight: 500, color: "#333333" }}
            align="center"
          >
            {t("text.editModeLgPersonalityTitle")}
          </Typography>
        }
        action={
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <LoadingButton
              variant="contained"
              loading={isLoading}
              disabled={!(formState.isDirty && formState.isValid)}
              onClick={handleSubmit((data) => formatData(data))}
            >
              {t("button.save")}
            </LoadingButton>
          </Box>
        }
      >
        <Card sx={{ width: "100%", p: 1 }}>
          <Stack spacing={2}>
            <Controller
              name={"best_qual"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  clearOnBlur
                  {...field}
                  multiple
                  options={bestQualitiesList}
                  freeSolo={true}
                  renderOption={(props, option) => (
                    <Box component="li" {...props}>
                      {option.label}
                    </Box>
                  )}
                  onChange={(_, data) => {
                    field.onChange(formatFreeSoloChipInput(data));
                  }}
                  onInputChange={(_, newInputValue) => {
                    const options = newInputValue.split(",");
                    if (options.length > 1) {
                      field.onChange(
                        field.value
                          .concat(options)
                          .map((x) => x.trim())
                          .filter((x) => x)
                      );
                    }
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      let getLabel = getLabelAndValue(
                        option,
                        bestQualitiesList
                      );
                      return (
                        <Chip
                          variant="outlined"
                          label={getLabel ? getLabel.label : option}
                          {...getTagProps({ index })}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("inputField.bestQuality")}
                      error={fieldState.error ? true : false}
                      helperText={
                        fieldState.error
                          ? fieldState.error.message
                          : mobile
                          ? t("helperText.chipMobile")
                          : t("helperText.chipDesktop")
                      }
                      // placeholder="Favorites"
                    />
                  )}
                />
              )}
              rules={{
                validate: {
                  lengthOfEachChip: (value) => {
                    return (
                      value.every((item) => item.length <= 50) ||
                      `${t("error.noMoreChar")
                        .split(" ")
                        .slice(0, 6)
                        .join(" ")} 50 ${t("error.noMoreChar").split(" ")[6]}`
                    );
                  },
                  chipArrayCount: (value) =>
                    value.length <= 10 ||
                    `${t("error.maxChipEntryError").split(" ")[0]} 10 ${t(
                      "error.maxChipEntryError"
                    ).slice(5)}`,
                  checkforspecialCharacters: (value) =>
                    value.every((item) => item.includes("|") === false) ||
                    t("error.pipeOperatorError"),
                },
              }}
            />

            <Controller
              name={"worst_qual"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  clearOnBlur
                  {...field}
                  multiple
                  options={worstQualitiesList}
                  freeSolo={true}
                  onInputChange={(_, newInputValue) => {
                    const options = newInputValue.split(",");

                    if (options.length > 1) {
                      field.onChange(
                        field.value
                          .concat(options)
                          .map((x) => x.trim())
                          .filter((x) => x)
                      );
                    }
                  }}
                  onChange={(_, data) =>
                    field.onChange(formatFreeSoloChipInput(data))
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      let getLabel = getLabelAndValue(
                        option,
                        worstQualitiesList
                      );
                      return (
                        <Chip
                          variant="outlined"
                          label={getLabel ? getLabel.label : option}
                          {...getTagProps({ index })}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("inputField.worstQuality")}
                      error={fieldState.error ? true : false}
                      helperText={
                        fieldState.error
                          ? fieldState.error.message
                          : mobile
                          ? t("helperText.chipMobile")
                          : t("helperText.chipDesktop")
                      }
                      // placeholder="Favorites"
                    />
                  )}
                />
              )}
              rules={{
                validate: {
                  lengthOfEachChip: (value) => {
                    return (
                      value.every((item) => item.length <= 50) ||
                      `${t("error.noMoreChar")
                        .split(" ")
                        .slice(0, 6)
                        .join(" ")} 50 ${t("error.noMoreChar").split(" ")[6]}`
                    );
                  },
                  chipArrayCount: (value) =>
                    value.length <= 10 ||
                    `${t("error.maxChipEntryError").split(" ")[0]} 10 ${t(
                      "error.maxChipEntryError"
                    ).slice(5)}`,
                  checkforspecialCharacters: (value) =>
                    value.every((item) => item.includes("|") === false) ||
                    t("error.pipeOperatorError"),
                },
              }}
            />

            <Controller
              name={"hobbies"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  clearOnBlur
                  {...field}
                  multiple
                  options={hobbiesList}
                  freeSolo={true}
                  onInputChange={(_, newInputValue) => {
                    const options = newInputValue.split(",");

                    if (options.length > 1) {
                      field.onChange(
                        field.value
                          .concat(options)
                          .map((x) => x.trim())
                          .filter((x) => x)
                      );
                    }
                  }}
                  onChange={(_, data) =>
                    field.onChange(formatFreeSoloChipInput(data))
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      let getLabel = getLabelAndValue(option, hobbiesList);
                      return (
                        <Chip
                          variant="outlined"
                          label={getLabel ? getLabel.label : option}
                          {...getTagProps({ index })}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("inputField.hobbies")}
                      error={fieldState.error ? true : false}
                      helperText={
                        fieldState.error
                          ? fieldState.error.message
                          : mobile
                          ? t("helperText.chipMobile")
                          : t("helperText.chipDesktop")
                      }
                      // placeholder="Favorites"
                    />
                  )}
                />
              )}
              rules={{
                validate: {
                  lengthOfEachChip: (value) => {
                    return (
                      value.every((item) => item.length <= 50) ||
                      `${t("error.noMoreChar")
                        .split(" ")
                        .slice(0, 6)
                        .join(" ")} 50 ${t("error.noMoreChar").split(" ")[6]}`
                    );
                  },
                  chipArrayCount: (value) =>
                    value.length <= 10 ||
                    `${t("error.maxChipEntryError").split(" ")[0]} 10 ${t(
                      "error.maxChipEntryError"
                    ).slice(5)}`,
                  checkforspecialCharacters: (value) =>
                    value.every((item) => item.includes("|") === false) ||
                    t("error.pipeOperatorError"),
                },
              }}
            />

            <Controller
              name={"fav_food"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  clearOnBlur
                  {...field}
                  multiple
                  options={[]}
                  freeSolo={true}
                  onInputChange={(_, newInputValue) => {
                    const options = newInputValue.split(",");

                    if (options.length > 1) {
                      field.onChange(
                        field.value
                          .concat(options)
                          .map((x) => x.trim())
                          .filter((x) => x)
                      );
                    }
                  }}
                  onChange={(_, data) => field.onChange(data)}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      let getLabel = getLabelAndValue(option, []);
                      return (
                        <Chip
                          variant="outlined"
                          label={getLabel ? getLabel.label : option}
                          {...getTagProps({ index })}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("inputField.favouriteFood")}
                      error={fieldState.error ? true : false}
                      helperText={
                        fieldState.error
                          ? fieldState.error.message
                          : mobile
                          ? t("helperText.chipMobile")
                          : t("helperText.chipDesktop")
                      }
                      // placeholder="Favorites"
                    />
                  )}
                />
              )}
              rules={{
                validate: {
                  lengthOfEachChip: (value) => {
                    return (
                      value.every((item) => item.length <= 50) ||
                      `${t("error.noMoreChar")
                        .split(" ")
                        .slice(0, 6)
                        .join(" ")} 50 ${t("error.noMoreChar").split(" ")[6]}`
                    );
                  },
                  chipArrayCount: (value) =>
                    value.length <= 10 ||
                    `${t("error.maxChipEntryError").split(" ")[0]} 10 ${t(
                      "error.maxChipEntryError"
                    ).slice(5)}`,
                  checkforspecialCharacters: (value) =>
                    value.every((item) => item.includes("|") === false) ||
                    t("error.pipeOperatorError"),
                },
              }}
            />

            <Controller
              name={"fav_sports"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  clearOnBlur
                  {...field}
                  multiple
                  options={sportsList}
                  freeSolo={true}
                  onInputChange={(_, newInputValue) => {
                    const options = newInputValue.split(",");

                    if (options.length > 1) {
                      field.onChange(
                        field.value
                          .concat(options)
                          .map((x) => x.trim())
                          .filter((x) => x)
                      );
                    }
                  }}
                  onChange={(_, data) =>
                    field.onChange(formatFreeSoloChipInput(data))
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      let getLabel = getLabelAndValue(option, sportsList);
                      return (
                        <Chip
                          variant="outlined"
                          label={getLabel ? getLabel.label : option}
                          {...getTagProps({ index })}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("inputField.favouriteSports")}
                      error={fieldState.error ? true : false}
                      helperText={
                        fieldState.error
                          ? fieldState.error.message
                          : mobile
                          ? t("helperText.chipMobile")
                          : t("helperText.chipDesktop")
                      }
                      // placeholder="Favorites"
                    />
                  )}
                />
              )}
              rules={{
                validate: {
                  lengthOfEachChip: (value) => {
                    return (
                      value.every((item) => item.length <= 50) ||
                      `${t("error.noMoreChar")
                        .split(" ")
                        .slice(0, 6)
                        .join(" ")} 50 ${t("error.noMoreChar").split(" ")[6]}`
                    );
                  },
                  chipArrayCount: (value) =>
                    value.length <= 10 ||
                    `${t("error.maxChipEntryError").split(" ")[0]} 10 ${t(
                      "error.maxChipEntryError"
                    ).slice(5)}`,
                  checkforspecialCharacters: (value) =>
                    value.every((item) => item.includes("|") === false) ||
                    t("error.pipeOperatorError"),
                },
              }}
            />

            <Controller
              name={"fav_music"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  clearOnBlur
                  {...field}
                  multiple
                  options={musicList}
                  freeSolo={true}
                  onInputChange={(_, newInputValue) => {
                    const options = newInputValue.split(",");

                    if (options.length > 1) {
                      field.onChange(
                        field.value
                          .concat(options)
                          .map((x) => x.trim())
                          .filter((x) => x)
                      );
                    }
                  }}
                  onChange={(_, data) =>
                    field.onChange(formatFreeSoloChipInput(data))
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      let getLabel = getLabelAndValue(option, musicList);
                      return (
                        <Chip
                          variant="outlined"
                          label={getLabel ? getLabel.label : option}
                          {...getTagProps({ index })}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("inputField.favouriteMusic")}
                      error={fieldState.error ? true : false}
                      helperText={
                        fieldState.error
                          ? fieldState.error.message
                          : mobile
                          ? t("helperText.chipMobile")
                          : t("helperText.chipDesktop")
                      }
                      // placeholder="Favorites"
                    />
                  )}
                />
              )}
              rules={{
                validate: {
                  lengthOfEachChip: (value) => {
                    return (
                      value.every((item) => item.length <= 50) ||
                      `${t("error.noMoreChar")
                        .split(" ")
                        .slice(0, 6)
                        .join(" ")} 50 ${t("error.noMoreChar").split(" ")[6]}`
                    );
                  },
                  chipArrayCount: (value) =>
                    value.length <= 10 ||
                    `${t("error.maxChipEntryError").split(" ")[0]} 10 ${t(
                      "error.maxChipEntryError"
                    ).slice(5)}`,
                  checkforspecialCharacters: (value) =>
                    value.every((item) => item.includes("|") === false) ||
                    t("error.pipeOperatorError"),
                },
              }}
            />

            <Controller
              name={"fav_books"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  clearOnBlur
                  {...field}
                  multiple
                  options={[]}
                  freeSolo={true}
                  onChange={(_, data) => field.onChange(data)}
                  onInputChange={(_, newInputValue) => {
                    const options = newInputValue.split(",");

                    if (options.length > 1) {
                      field.onChange(
                        field.value
                          .concat(options)
                          .map((x) => x.trim())
                          .filter((x) => x)
                      );
                    }
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      let getLabel = getLabelAndValue(option, []);
                      return (
                        <Chip
                          variant="outlined"
                          label={getLabel ? getLabel.label : option}
                          {...getTagProps({ index })}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("inputField.favouriteBooks")}
                      error={fieldState.error ? true : false}
                      helperText={
                        fieldState.error
                          ? fieldState.error.message
                          : mobile
                          ? t("helperText.chipMobile")
                          : t("helperText.chipDesktop")
                      }
                      // placeholder="Favorites"
                    />
                  )}
                />
              )}
              rules={{
                validate: {
                  lengthOfEachChip: (value) => {
                    return (
                      value.every((item) => item.length <= 50) ||
                      `${t("error.noMoreChar")
                        .split(" ")
                        .slice(0, 6)
                        .join(" ")} 50 ${t("error.noMoreChar").split(" ")[6]}`
                    );
                  },
                  chipArrayCount: (value) =>
                    value.length <= 10 ||
                    `${t("error.maxChipEntryError").split(" ")[0]} 10 ${t(
                      "error.maxChipEntryError"
                    ).slice(5)}`,
                  checkforspecialCharacters: (value) =>
                    value.every((item) => item.includes("|") === false) ||
                    t("error.pipeOperatorError"),
                },
              }}
            />

            <Controller
              name={"persntly_add_details"}
              control={control}
              defaultValue={defaultValues.persntly_add_details}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("inputField.AdditionalDetails")}
                  type={"text"}
                  inputProps={{
                    maxLength: 1001,
                  }}
                  error={fieldState.error ? true : false}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                  InputProps={{
                    inputComponent: TextareaAutosize,
                    endAdornment: (
                      <HelperIcon
                        helperText={
                          <Typography variant="body1">
                            {t("helperText.additionalDetails")}
                          </Typography>
                        }
                      />
                    ),
                  }}
                  sx={{ width: "100%" }}
                />
              )}
              rules={{
                maxLength: {
                  value: 1000,
                  message: t("error.maxCharacters", {
                    param1: 1000,
                  }),
                },
              }}
            />

            <Controller
              name={"persnlty_visib"}
              control={control}
              // defaultValue={defaultValues.persnlty_visib}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  classes={{
                    root: classes.textFieldRoot, // apply class here
                  }}
                  select={true}
                  label={t("inputField.Visiblity")}
                  value={field.value}
                  required={true}
                  sx={{ width: "100%" }}
                  InputProps={{
                    endAdornment: (
                      <HelperIcon helperText={<VisiblityHelperText />} />
                    ),
                  }}
                  error={fieldState.error ? true : false}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                >
                  {visibilityTypes.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: {
                  value: true,
                  message: t("error.fieldIsRequired"),
                },
              }}
            />
          </Stack>
        </Card>
      </CustomDialog>
    </>
  );
};
