import React, { useEffect, useState } from "react";
import { useGetWishQuery } from "../../redux/slice/wish";
import { findOwner, findWho, isBlocked, isSelf } from "../../utils/functions";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { t } from "i18next";
import { NewWish } from "../forms/NewWish";
import { useSelector } from "react-redux";
import { WishContainer } from "../organism/WishContainer";
import { GrannyTip } from "../../UI/GrannyTip";

export const Wish = ({ lgId }) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const { data, isLoading } = useGetWishQuery(lgId);
  const { activeLgId } = useSelector((state) => state.profile);
  const { userStatus } = useSelector((state) => state.config);
  const [showButton, setShowButton] = useState(false);
  const [person, setPerson] = useState(undefined);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setShowButton(
      isBlocked(lgId)
        ? false
        : isSelf(lgId)
        ? false
        : findOwner(lgId)
        ? true
        : false
    );
    setPerson(findWho(lgId));
  }, [lgId]);
  return (
    <>
      <Stack spacing={1} sx={{ mt: 1, width: "100%" }}>
        <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
          <Stack spacing={1} sx={{ width: "100%" }}>
            <Box sx={{ width: "100%", alignItems: "center" }}>
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  justifyContent: "flex-start",
                }}
              >
                {showButton && (
                  <Stack spacing={1}>
                    <Button
                      variant="contained"
                      onClick={() => setOpen(true)}
                      disabled={userStatus === "inactive"}
                    >
                      {t("button.shareWish")}
                    </Button>
                  </Stack>
                )}
                {open && (
                  <NewWish open={open} close={setOpen} lg_id={activeLgId} />
                )}
              </Box>
            </Box>
            {data && !isLoading && data.wishesList.length > 0 && (
              <Divider></Divider>
            )}
            {data && !isLoading && data.wishesList.length > 0 ? (
              <Stack spacing={1}>
                {data.wishesList.map((item, idx) => (
                  <div key={idx}>
                    <WishContainer {...item} lg_id={activeLgId} />
                  </div>
                ))}
              </Stack>
            ) : (
              <Box
                sx={{
                  pb: 5,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <GrannyTip
                  message={
                    <Typography variant="body1">
                      {showButton
                        ? t("granny.emptyWishP1", {
                            param1: `${person?.name}`,
                          })
                        : t("text.noWish")}
                    </Typography>
                  }
                  sx={{
                    width: "80%",
                  }}
                />
              </Box>
            )}
          </Stack>
        </Box>
      </Stack>
    </>
  );
};
